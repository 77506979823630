import { EditContactFormData } from "components/Contacts/EditContactDialog";
import { PersonFacilityContactsInput } from "generated-graphql/graphql";
import { omit } from "lodash";

export function transform(
  data: EditContactFormData
): PersonFacilityContactsInput {
  if (!data.person) {
    throw new Error("Person data is required");
  }
  const { facilityContacts, ...personInput } = data.person;
  return {
    facilityContactInput:
      facilityContacts?.map((contact) => ({
        facilityId: contact.facility.id,
        reportingRoles: contact.reportingRoles ?? [],
      })) ?? [],
    person: omit(personInput, "issues", "user"),
  };
}
