import { useMutation } from "@apollo/client";
import DataObject from "@mui/icons-material/DataObject";
import Description from "@mui/icons-material/Description";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  FormLabel,
  Grid,
  Icon,
  Link,
  List,
  ListItemText,
  ListItem as MUIListItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import {
  VerifyAndSubmitFormDialog,
  VerifyAndSubmitFormState,
} from "components/VerifyAndSubmitFormDialog";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  TierIiReportEncampStatus,
  TierIiReportOrgStatus,
} from "generated-graphql/graphql";
import { compact } from "lodash";
import { useState } from "react";
import CopyableTypography from "../../../../components/Typography/CopyableTypography";
import { AssignDialog } from "../AssignmentManagerTable/BulkActionsMenu/Assign";
import { useUnassignActivities } from "../AssignmentManagerTable/BulkActionsMenu/Unassign";
import { BULK_VERIFY_MUTATION } from "../AssignmentManagerTable/BulkActionsMenu/VerifyAndSubmit";
import { useReportDetails } from "./useReportDetails";
const ListItem = styled(MUIListItem)`
  padding-left: ${(props) => props.theme.spacing(1)};
`;

export function Facility() {
  const { data } = useReportDetails();
  const alerts = useAlerts();
  const [mutate, { loading: bulkVerifyLoading }] =
    useMutation(BULK_VERIFY_MUTATION);

  const [dialogState, setDialogState] = useState<
    null | "verifyAndSubmit" | "assign"
  >(null);

  const { unassign, loading: unassigning } = useUnassignActivities();

  const closeDialogs = () => setDialogState(null);

  const onSubmit = async (formState: VerifyAndSubmitFormState) => {
    if (data?.tierIIReport?.id) {
      mutate({
        variables: {
          reportIds: [data.tierIIReport.id],
          verifier: {
            personId: formState.personId ?? null,
            verifiedAt: formState.date,
            verifierFullName: `${formState.firstName} ${formState.lastName}`,
            verifierTitle: formState.title,
          },
        },
        refetchQueries: ["GetTierIIReport"],
        update: (cache, { data: mutationResponse }) => {
          if (mutationResponse?.bulkVerifyAndSubmitReports?.succeeded) {
            cache.modify({
              id: cache.identify({
                __typename: "TierIIReport",
                id: data.tierIIReport.id,
              }),
              fields: {
                encampStatus: () => TierIiReportEncampStatus.Processing,
                organizationStatus: () => TierIiReportOrgStatus.Verified,
              },
            });
          }
        },
        onCompleted: () => {
          alerts.success("Report verified and submitted");
        },
        onError: (err) => {
          alerts.error("Failed to verify and submit report", err);
        },
      });
    }
  };

  const theme = useTheme();

  const hasOpenReview =
    data?.tierIIReport.reviewers.some((r) => !r.isComplete) ?? false;

  const hasValidationErrors = (data?.tierIIReport.issues ?? []).length > 0;

  return (
    <Stack
      direction={"column"}
      component={Paper}
      sx={{ border: `1px solid ${theme.palette.divider}`, padding: 1 }}
    >
      <Stack direction={"row"}>
        <Typography variant="h6">Facility</Typography>
        {data?.tierIIReport && (
          <>
            {/* ash report link */}
            <Link
              sx={{ cursor: "pointer" }}
              onClick={async () => {
                window.open(
                  `/o/${data.tierIIReport.tenantId}/chemicals/reports/${data.tierIIReport.id}`,
                  "_blank"
                );
              }}
            >
              <Tooltip title="Open Report">
                <Icon>
                  <Description />
                </Icon>
              </Tooltip>
            </Link>
            {/* iceberg report link */}
            <Link
              href={`https://s3.console.aws.amazon.com/s3/object/encamp-ash-tenant${
                import.meta.env.VITE_ENV === "dev"
                  ? "11330-dev"
                  : "142400-release"
              }?region=us-east-1&bucketType=general&prefix=iceberg/TierIIReport/${
                data.tierIIReport.id
              }.json`}
              target="_blank"
            >
              <Tooltip title="Open iceberg in AWS S3">
                <Icon>
                  <DataObject />
                </Icon>
              </Tooltip>
            </Link>
          </>
        )}
      </Stack>
      <Stack direction={"row"}>
        <List sx={{ flex: 1 }}>
          <ListItem>
            <ListItemText secondary="Organization">
              <CopyableTypography text={data?.tierIIReport.tenant?.name} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Facility Name">
              <CopyableTypography text={data?.tierIIReport.facility.name} />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText secondary="Company Name">
              <CopyableTypography
                text={data?.tierIIReport.facility.companyName ?? "N/A"}
              />
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                data?.tierIIReport.facility.reportsManually ? "Manual" : "Auto"
              }
              secondary="Portal Submission Mode"
            />
          </ListItem>
        </List>
        <List sx={{ flex: 1 }}>
          <ListItem>
            <ListItemText secondary="City">
              <CopyableTypography
                text={`${data?.tierIIReport.facility.city}`}
              />
            </ListItemText>
            <ListItemText secondary="State">
              <CopyableTypography
                text={`${data?.tierIIReport.facility.state}`}
              />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Address">
              <CopyableTypography
                text={`${data?.tierIIReport.facility.streetAddress1}`}
              />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="Zip Code">
              <CopyableTypography text={data?.tierIIReport.facility.zip} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText secondary="County">
              <CopyableTypography text={data?.tierIIReport.facility.county} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemText
              primary={data?.tierIIReport.productCount}
              secondary="Number of chemicals"
            />
          </ListItem>
          <ListItem>
            <ListItemText secondary="NAICS Code">
              <CopyableTypography
                text={`${data?.tierIIReport.facility.naicsCode}`}
              />
            </ListItemText>
          </ListItem>
        </List>
      </Stack>
      {/* Alternate Ids */}
      {data?.tierIIReport.facility.facilityAlternateIds &&
        data?.tierIIReport.facility.facilityAlternateIds.length > 0 && (
          <>
            <Divider sx={{ marginY: "1rem" }} />
            <Stack direction={"column"} spacing={2} sx={{ paddingX: "1rem" }}>
              <FormLabel>Facility Alternate IDs</FormLabel>
              <List sx={{ flex: 1, paddingX: "none" }}>
                {data?.tierIIReport.facility.facilityAlternateIds.map(
                  ({ id, type, value }) => (
                    <ListItem key={id}>
                      <ListItemText secondary={type}>
                        <CopyableTypography text={value} />
                      </ListItemText>
                    </ListItem>
                  )
                )}
              </List>
            </Stack>
          </>
        )}

      <Divider sx={{ marginY: "1rem" }} />
      <Grid container spacing={2} paddingLeft={2}>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText secondary="Report Kind">
              <CopyableTypography
                text={prettyPrintEnumValue(data?.tierIIReport.reportKind)}
              />
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText secondary="Organization Status">
              <CopyableTypography
                text={prettyPrintEnumValue(
                  data?.tierIIReport.organizationStatus
                )}
              />
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText secondary="Encamp Status">
              <CopyableTypography
                text={prettyPrintEnumValue(data?.tierIIReport.encampStatus)}
              />
            </ListItemText>
          </ListItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <ListItem>
            <Box display="flex">
              <LoadingButton
                loading={bulkVerifyLoading}
                disabled={
                  data?.tierIIReport?.encampStatus !==
                  TierIiReportEncampStatus.AwaitingVerification
                }
                variant="outlined"
                onClick={() => setDialogState("verifyAndSubmit")}
              >
                Verify and Submit
              </LoadingButton>
              <VerifyAndSubmitFormDialog
                open={dialogState === "verifyAndSubmit"}
                onClose={closeDialogs}
                onSubmit={onSubmit}
                loading={bulkVerifyLoading}
                defaultVerifier={{
                  fullName: data?.tierIIReport?.verifierFullName ?? undefined,
                  title: data?.tierIIReport?.verifierTitle ?? undefined,
                  person: data?.tierIIReport?.verifier ?? undefined,
                }}
                withConfirmation={hasOpenReview || hasValidationErrors}
                skippedDueToOpenReviewsCount={hasOpenReview ? 1 : 0}
                skippedDueToVerificationCount={0}
                verifiableRowsWithoutOpenReviewsCount={hasOpenReview ? 0 : 1}
                reportsWithValidationErrorsCount={hasValidationErrors ? 1 : 0}
              />
            </Box>
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText secondary="Current Activity Assignee">
              <CopyableTypography
                text={
                  data?.tierIIReport.currentWorkflow?.currentActivity?.assignee
                    ?.email ?? "N/A"
                }
              />
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem>
            <Box display="flex">
              <LoadingButton
                disabled={
                  !data?.tierIIReport.currentWorkflow?.currentActivity?.id
                }
                variant="outlined"
                onClick={() => setDialogState("assign")}
              >
                Assign Activity
              </LoadingButton>
              <AssignDialog
                open={dialogState === "assign"}
                onClose={closeDialogs}
                activityIds={compact([
                  data?.tierIIReport.currentWorkflow?.currentActivity?.id,
                ])}
              />
            </Box>
          </ListItem>
          <ListItem>
            <Box display="flex">
              <LoadingButton
                disabled={
                  !data?.tierIIReport.currentWorkflow?.currentActivity?.assignee
                }
                variant="outlined"
                onClick={() =>
                  unassign(
                    compact([
                      data?.tierIIReport.currentWorkflow?.currentActivityId,
                    ])
                  )
                }
                loading={unassigning}
              >
                Unassign Activity
              </LoadingButton>
            </Box>
          </ListItem>
        </Grid>
      </Grid>

      <Divider sx={{ marginY: "1rem" }} />
      <Grid container spacing={2} paddingLeft={2}>
        <Grid item xs={12} md={6}>
          <ListItem>
            <ListItemText secondary="Job Status">
              <CopyableTypography
                // ML 1.17.24 we expect the first job to be the most
                // recent because of the orderBy clause in the Facility/job resolver
                // apps/api/src/resolvers/facility/job.ts
                text={prettyPrintEnumValue(
                  data?.tierIIReport.facility.jobs?.[0]?.status ?? "N/A"
                )}
              />
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>
    </Stack>
  );
}
