import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import {
  Document,
  DocumentAssociationsInput,
  DocumentInput2,
  UploadedDocumentQuery,
} from "generated-graphql/graphql";
import { useCallback, useMemo, useState } from "react";
import { uploadFileDirectly } from "../util/uploadFile";
import { useUpsertDocument } from "./useUpsertDocument";

const UPLOADED_DOCUMENT_QUERY = gql(`
  query UploadedDocument($id: ID!) {
    document(id: $id) {
      id
      documentType
      title
      fileExtension
      storageLink
      downloadLink
    }
  }
`);

/**
 * Hook for uploading a document and upserting it to the database
 * @returns
 */
export function useDocumentUpload(config?: {
  onSuccess?: (document: UploadedDocumentQuery["document"]) => void;
  onError?: (error: Error) => void;
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [document, setDocument] = useState<
    UploadedDocumentQuery["document"] | null
  >(null);
  const [error, setError] = useState<Error | null>(null);

  const { upsertDocument } = useUpsertDocument();
  const [getDocument] = useLazyQuery(UPLOADED_DOCUMENT_QUERY);

  const uploadFile = useCallback(
    async ({
      file,
      documentData,
      currentSubmissionDocument,
      associations,
    }: {
      file?: File;
      documentData: Document | DocumentInput2;
      currentSubmissionDocument: boolean;
      associations?: DocumentAssociationsInput[];
    }) => {
      setUploadComplete(false);
      setIsUploading(true);
      setDocument(null);
      setError(null);

      if (!documentData.tenantId) {
        throw new Error("Tenant ID is required");
      }

      try {
        let documentKey: string;

        if (file) {
          documentKey = await uploadFileDirectly(file, {
            tenantId: documentData.tenantId ?? "",
          });
        } else {
          if (!documentData.storageLink) {
            throw new Error("Storage link is required");
          }

          documentKey = documentData.storageLink;
        }

        const documentId = await upsertDocument({
          documentData: {
            ...documentData,
            storageLink: documentKey,
          },
          associations,
          currentSubmissionDocument,
        });

        if (!documentId) {
          throw new Error("Document ID not found");
        }
        const document = await getDocument({
          variables: { id: documentId },
        });

        if (!document.data?.document) {
          throw new Error("Document not found");
        }

        setDocument(document.data.document);
        setUploadComplete(true);
        config?.onSuccess?.(document.data.document);
      } catch (err) {
        setError(err as Error);
        config?.onError?.(err as Error);
      } finally {
        setIsUploading(false);
      }
    },
    [upsertDocument, getDocument, config]
  );

  return useMemo(
    () => ({
      isUploading,
      uploadComplete,
      document,
      error,
      uploadFile,
    }),
    [isUploading, uploadComplete, document, error, uploadFile]
  );
}

export function useUploadFiles() {
  return useCallback(
    async (
      documentsWithMaybeFile: { file?: File; documentData: DocumentInput2 }[]
    ) => {
      if (!documentsWithMaybeFile.every((doc) => doc.documentData.tenantId)) {
        throw new Error("Tenant ID is required for all documents");
      }
      return await Promise.all(
        documentsWithMaybeFile.map(({ file, documentData }) =>
          file
            ? uploadFileDirectly(file, {
                tenantId: documentData.tenantId ?? "",
              })
            : Promise.resolve(documentData.storageLink)
        )
      );
    },
    []
  );
}
