import {
  DynamicFieldInput,
  DynamicFieldType,
  DynamicField,
  ProgramArea,
} from "generated-graphql/graphql";

export function transform(
  input: DynamicField & { type?: ProgramArea | null }
): DynamicFieldInput {
  let value = input.value;
  if (input.inputType === DynamicFieldType.Numeric && value !== null) {
    const parsedValue = Number(String(value));
    if (!Number.isNaN(parsedValue)) {
      value = parsedValue;
    }
  }
  return {
    jurisdiction: input.jurisdiction,
    key: input.key,
    type: input.type ?? ProgramArea.Epcra,
    value: value,
  };
}
