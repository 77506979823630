import { Typography } from "@mui/material";
import Science from "@mui/icons-material/Science";
import { Issue } from "generated-graphql/graphql";
import pluralize from "pluralize";
import { Link } from "react-router-dom";
import { IssueCount } from "components/IssueCount";
import { useMeasurements } from "hooks/useMeasurements";
import { useProducts } from "hooks/useProducts";
import { BaseLink } from "./BaseLink";
import { ReportHasNoChemicalsAlert } from "components/Alerts/ReportHasNoChemicalsAlert";
import { useMemo } from "react";

function buildLink(
  tenantId: string,
  reportId?: string,
  facilityId?: string,
  chemicalId?: string,
  facilityChemicalId?: string,
  hasProducts?: boolean,
  hasMeasurements?: boolean
) {
  const inStepper = !!reportId;
  // Cases where we can't build a link, maybe because there is just products.
  if (
    (!inStepper && !facilityId) ||
    (!hasMeasurements && !facilityChemicalId) ||
    (!hasProducts && !chemicalId)
  ) {
    return null;
  }
  let link = `/o/${tenantId}/chemicals`;
  link += inStepper ? `/reports/${reportId}` : `/inventory/${facilityId}`;
  link += hasProducts ? "/other-chemicals" : `/chemicals`;
  link += hasMeasurements
    ? `/measurements/${chemicalId}`
    : `/${facilityChemicalId}`;
  return link;
}

export const FacilityChemicalLink = ({
  facilityChemicalId,
  chemicalId,
  reportId,
  tenantId,
  facilityId,
  name,
  issues = [],
}: {
  facilityChemicalId: string;
  chemicalId: string;
  tenantId: string;
  name: string;
  issues?: Issue[];
  reportId?: string;
  facilityId?: string;
}) => {
  const { loading: measurementsLoading, hasMeasurements } = useMeasurements(
    facilityId || ""
  );
  const { loading: productsLoading, hasProducts } = useProducts();
  const link = buildLink(
    tenantId,
    reportId,
    facilityId,
    chemicalId,
    facilityChemicalId,
    hasProducts,
    hasMeasurements
  );
  const filteredIssues = useMemo(
    () =>
      issues.filter((issue) =>
        hasMeasurements
          ? issue.schemaPath?.includes("FacilityChemicalMeasurement")
          : issue.schemaPath?.includes("FacilityChemical")
      ),
    [issues, hasMeasurements]
  );

  const issueCount = filteredIssues.length;
  const linkContent =
    issueCount === 0 ? (
      <>
        <Science fontSize="small" sx={{ mr: 1 }} />
        View your{" "}
        <Link
          to={link ?? ""}
          target="_self"
          rel="noopener noreferrer"
          style={{ color: "inherit", overflow: "hidden" }}
        >
          <Typography noWrap>{name}</Typography>
        </Link>{" "}
        {" inventory entry"}
      </>
    ) : (
      <>
        Fix&nbsp;
        <IssueCount issueCount={issueCount} />
        &nbsp;
        {pluralize("issue", issueCount)} in the inventory for{" "}
        <Link
          to={link ?? ""}
          target="_self"
          rel="noopener noreferrer"
          style={{ color: "inherit", overflow: "hidden" }}
        >
          <Typography noWrap>{name}</Typography>
        </Link>{" "}
      </>
    );

  if (measurementsLoading || productsLoading || !link) {
    return null;
  }

  return (
    <BaseLink
      linkContent={linkContent}
      link={link}
      issueCount={issueCount}
      openInNew={false}
    />
  );
};
