import { useLazyQuery } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";
import FindReplace from "@mui/icons-material/FindReplace";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { DocumentPreview } from "components/DocumentPreview";
import { Dropzone } from "components/Dropzone";
import { DateField } from "components/Forms/DateField";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Controller,
  useController,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import { FormState, UploadFile } from "./types";
import { CaliforniaDocumentTypes } from "util/constants";
import { useDocumentDownloadLink } from "hooks/useDocumentDownloadLink";
import {
  FacilityDocumentPicker,
  FacilityDocumentPickerItem,
} from "components/FacilityDocumentPicker";
import { DocumentType } from "generated-graphql/graphql";
import { gql } from "generated-graphql";

const GET_HMBP_DOCUMENT_QUERY = gql(`
  query GetHmbpDocument($id: ID!) {
    document(id: $id) {
        id
        title
        documentType
        downloadLink
        fileExtension
        storageLink
        authoredAt
        description
    }
  }
`);

type UploadProps = {
  loading?: boolean;
  facilityId: string;
  handleBack: () => void;
};

export function Upload({ loading, facilityId, handleBack }: UploadProps) {
  const theme = useTheme();
  const alerts = useAlerts();
  const contentElementRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null); // Ref for hidden file input
  const { control, register } = useFormContext<FormState>();
  const [replaceIndex, setReplaceIndex] = useState<number | null>(null);
  const [isAddingAnotherFile, setIsAddingAnotherFile] = useState(false);
  const { append, fields, remove, update } = useFieldArray<
    FormState,
    "upload",
    "uniqueId"
  >({
    control,
    name: "upload",
    keyName: "uniqueId",
  });

  const [downloadPreviewUrl] = useDocumentDownloadLink();
  const handleDocumentSelect = async (document: FacilityDocumentPickerItem) => {
    const { data } = await downloadPreviewUrl({
      variables: { id: document.id },
    });

    append({
      document,
      extension: document.fileExtension ?? "",
      previewURL: data?.getDocumentDownloadLink ?? null,
    });

    if (isAddingAnotherFile) {
      setIsAddingAnotherFile(false);
    }
  };

  const handleFileSelect = async (files: File[]) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    append({
      file,
      extension: file.name.split(".").pop()?.toLowerCase() ?? "",
      previewURL: URL.createObjectURL(file),
    });

    if (isAddingAnotherFile) {
      setIsAddingAnotherFile(false);
    }
  };

  const handleReplaceFile = (index: number) => {
    setReplaceIndex(index);
    fileInputRef.current?.click();
  };

  const onFileReplace = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && replaceIndex !== null) {
      update(replaceIndex, {
        ...fields[replaceIndex],
        file,
        document: {
          ...fields[replaceIndex].document,
          id: fields[replaceIndex].document?.id ?? "",
          title: file.name,
          storageLink: fields[replaceIndex].document?.storageLink ?? "",
          documentType:
            fields[replaceIndex].document?.documentType ?? DocumentType.Other,
        },
        extension: file.name.split(".").pop()?.toLowerCase() ?? "",
        previewURL: URL.createObjectURL(file),
      });

      // Reset the replace index and clear the file input
      setReplaceIndex(null);
      event.target.value = "";
    }
  };

  const [download] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_LINK_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data?.getDocumentDownloadLink) {
        return alerts.error("Error fetching download link");
      }

      alerts.success("Success fetching download link");
      window.open(data.getDocumentDownloadLink, "_blank");
    },
    onError: () => {
      alerts.error("Error fetching download link");
    },
  });

  const handleDelete = async (upload: UploadFile, index: number) => {
    remove(index);
    return;
  };

  const handleDownload = async (documentId: string) => {
    download({
      variables: { id: documentId },
    });
  };

  const { fieldState, field } = useController({
    control,
    name: "documentIds",
  });

  const documentIds = useMemo(() => field.value ?? [], [field.value]);

  const [getHmbpDocument] = useLazyQuery(GET_HMBP_DOCUMENT_QUERY);

  const ref = useRef(false);

  // Add this effect to preload documents
  useEffect(() => {
    if (ref.current) return;
    const loadInitialDocuments = async () => {
      if (documentIds && documentIds.length > 0) {
        // Load each document in the documentIds array
        for (const documentId of documentIds) {
          const { data } = await getHmbpDocument({
            variables: { id: documentId },
          });

          append({
            document: data?.document,
            extension: data?.document?.fileExtension ?? "",
            previewURL: data?.document?.downloadLink ?? null,
          });
        }
      }
    };

    loadInitialDocuments();
    ref.current = true;
  }, [documentIds, getHmbpDocument, append]);

  // Effect to handle scrolling the dropzone into view when adding another file
  useEffect(() => {
    setTimeout(() => {
      if (contentElementRef.current?.lastChild) {
        (contentElementRef.current.lastChild as Element).scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 0);
  }, [isAddingAnotherFile]);

  return (
    <Stack
      direction="column"
      sx={{
        maxHeight: "80vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onFileReplace}
      />
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          paddingRight:
            (fields && fields.length > 1) || isAddingAnotherFile
              ? theme.spacing(2)
              : 0,
        }}
      >
        <Typography variant="body1">Upload Document</Typography>
        <ErrorDisplay {...fieldState} />

        {fields.length === 0 && (
          <Stack spacing={2} marginTop={theme.spacing(2)}>
            <FacilityDocumentPicker
              facilityId={facilityId}
              documentTypes={CaliforniaDocumentTypes}
              onSelect={handleDocumentSelect}
              disabled={loading ?? false}
            />

            <UploadFormDropzone onDrop={handleFileSelect} />
          </Stack>
        )}

        {fields && fields.length > 0 && (
          <Stack ref={contentElementRef} gap={theme.spacing(3)}>
            {fields.map((upload, index) => {
              return (
                <Fragment key={index}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="caption">
                      {upload.file?.name
                        ? upload.file.name
                        : upload.document?.title}
                    </Typography>
                    <Stack direction="row" gap={theme.spacing(1)}>
                      <IconButton onClick={() => handleDelete(upload, index)}>
                        <Delete color="primary" />
                      </IconButton>
                      <IconButton onClick={() => handleReplaceFile(index)}>
                        <FindReplace color="primary" />
                      </IconButton>
                      {upload.document?.id && !upload.file && (
                        <IconButton
                          onClick={() =>
                            handleDownload(upload.document?.id ?? "")
                          }
                        >
                          <Download color="primary" />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>

                  {upload.extension === "pdf" && upload.previewURL && (
                    <Box sx={{ height: theme.spacing(30) }}>
                      <DocumentPreview fileUrl={upload.previewURL} />
                    </Box>
                  )}

                  <Controller
                    control={control}
                    name={`upload.${index}.document.authoredAt`}
                    render={({ field }) => (
                      <DateField
                        control={control}
                        {...register(field.name, { required: true })}
                        label="Date Authored"
                        required
                        sx={{
                          width: "40%",
                          marginTop: 6,
                          marginBottom: -3,
                        }}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`upload.${index}.document.description`}
                    render={({ field }) => (
                      <FormTextField
                        control={control}
                        name={field.name}
                        label="Description / Comments"
                        sx={{ marginBottom: -3 }}
                      />
                    )}
                  />
                </Fragment>
              );
            })}
            {!isAddingAnotherFile && fields.length > 0 && (
              <Button
                startIcon={<Add />}
                onClick={() => setIsAddingAnotherFile(true)}
                sx={{ alignSelf: "flex-start" }}
              >
                Add Another
              </Button>
            )}
            {isAddingAnotherFile && (
              <Stack spacing={2} marginTop={theme.spacing(2)}>
                <FacilityDocumentPicker
                  facilityId={facilityId}
                  documentTypes={CaliforniaDocumentTypes}
                  onSelect={handleDocumentSelect}
                  disabled={loading ?? false}
                  disabledDocumentIds={documentIds}
                />
                <UploadFormDropzone onDrop={handleFileSelect} />
              </Stack>
            )}
          </Stack>
        )}
      </Box>

      <Stack
        direction="row"
        gap={theme.spacing(2)}
        sx={{
          paddingTop: theme.spacing(3),
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={handleBack} variant="outlined">
          Back
        </Button>
        <SaveButton
          loading={loading ?? false}
          disabled={fields.length === 0 || fieldState.invalid}
        />
      </Stack>
    </Stack>
  );
}

type UploadFormDropzoneProps = {
  onDrop: (files: File[]) => void;
};

function UploadFormDropzone({ onDrop }: UploadFormDropzoneProps) {
  const theme = useTheme();

  return (
    <Dropzone
      label="Drag and drop your document or click to select file"
      dragActiveText="Drop the file here"
      onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
      sx={{ height: theme.spacing(30) }}
    />
  );
}
