import {
  StateFieldsForm,
  StateFieldsFormProps,
} from "components/Forms/StateFieldsForm";
import { useFormContext } from "react-hook-form";
import { FacilityDetailsInput } from "./FacilityProfile";
import { useParams } from "react-router-dom";
import { useFacilityStateFields } from "./useFacility";
import { ProgramArea } from "generated-graphql/graphql";
import { SubSection } from "components/SubSection";
import { Typography } from "@mui/material";

interface FacilityStateFieldsFormProps extends StateFieldsFormProps {
  jurisdictions?: string[];
}

export function FacilityStateFieldsForm(props: FacilityStateFieldsFormProps) {
  const form = useFormContext<FacilityDetailsInput>();
  const { facilityId } = useParams<{ facilityId: string }>();
  const { jurisdictions, ...rest } = props;

  const { error } = useFacilityStateFields(facilityId, jurisdictions, {
    onCompleted: (data) => {
      if (
        // if we get back an array, empty or not, set it
        data?.facilityStateFields !== undefined &&
        data?.facilityStateFields !== null
      ) {
        const mappedFields = data.facilityStateFields.map((field) => ({
          ...field,
          value: field.value ?? null,
          type: ProgramArea.Epcra,
        }));

        form.setValue("stateFields", mappedFields);
      }
    },
  });

  if (error) return <div>Error loading state fields</div>;

  if (jurisdictions?.includes("CA")) {
    return (
      <SubSection>
        <Typography>
          Because this facility is in California, the California HMBP questions
          will appear in the facility's Tier II report in Chemicals.
        </Typography>
      </SubSection>
    );
  }

  if (props.fields?.length === 0) {
    return (
      <SubSection>
        <Typography>No additional information needed.</Typography>
      </SubSection>
    );
  } else {
    return (
      <SubSection>
        <StateFieldsForm {...rest} />
      </SubSection>
    );
  }
}
