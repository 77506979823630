import { Button, Stack, Typography, useTheme } from "@mui/material";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { useFormContext } from "react-hook-form";
import { DocumentOption } from "./types";

type DocumentOptionsProps = {
  handleClose: () => void;
  handleNext: () => void;
};

export function DocumentOptions({
  handleClose,
  handleNext,
}: DocumentOptionsProps) {
  const theme = useTheme();
  const { control, watch } = useFormContext();

  const option: DocumentOption | null = watch("documentOption");

  return (
    <>
      <RadioGroupField
        control={control}
        name="documentOption"
        label="Document options"
        direction="column"
        radioOptions={[
          { value: DocumentOption.Upload, label: "Upload Document(s)" },
          {
            value: DocumentOption.ProvideElsewhere,
            label: "Provided Elsewhere in CERS",
          },
          { value: DocumentOption.Exempt, label: "Exempt" },
        ]}
      />

      <Typography variant="caption" marginBottom={theme.spacing(2)}>
        Please contact Encamp Support if you need another option for your
        document(s).
      </Typography>

      <Stack
        direction="row"
        gap={theme.spacing(2)}
        justifyContent="flex-end"
        sx={{ marginTop: theme.spacing(2) }}
      >
        <Stack direction="row" gap={theme.spacing(2)}>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!option) {
                return;
              }

              handleNext();
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
