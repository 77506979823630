import { useQuery } from "@apollo/client";
import { Stack } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid-premium";
import { gql } from "generated-graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DocumentsGrid } from "../Documents/DocumentsGrid";

export const FACILITY_DOCUMENTS = gql(`
  query FacilityDocuments($facilityId: ID!, $search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facility(id: $facilityId) {
      id
      name
      # This is needed to return issued related to documents for a facility
      documents {
        id
        documentType
        storageLink
        currentSubmissionDocument
      }
      issues {
        ...issue
      }
    }
    documents(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      items {
        ...DocumentGridRow
        facilities {
          id
          name
        }
      }
    }
  }
`);

export const Documents = () => {
  const { tenantId, facilityId } = useParams();
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "createdAt", sort: "desc" },
  ]);
  const { data, previousData, loading } = useQuery(FACILITY_DOCUMENTS, {
    variables: {
      facilityId: facilityId ?? "",
      search: `tenantId:${tenantId} facilityId:${facilityId}`,
      sort: sortModel,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    },
    skip: !facilityId,
    fetchPolicy: "cache-and-network",
  });

  const facilityDocumentIssues = useMemo(
    () =>
      data?.facility?.issues.filter(
        (issue) =>
          issue.modelName === "Facility" && issue.key === "facilityDocuments"
      ) ?? [],
    [data?.facility?.issues]
  );

  useBreadcrumb([
    {
      label: "Facilities",
      to: `/o/${tenantId}/facilities`,
    },
    {
      label: data?.facility?.name ?? "",
      to: `/o/${tenantId}/facilities/${facilityId}/profile`,
    },
    {
      label: "Documents",
    },
  ]);

  return (
    <Stack sx={{ px: 3, pt: 4 }}>
      <DocumentsGrid
        loading={loading}
        count={(data?.documents.count ?? 0) + facilityDocumentIssues.length}
        documents={data?.documents.items ?? previousData?.documents.items ?? []}
        tenantId={tenantId}
        facilityDocumentIssues={facilityDocumentIssues}
        facility={
          data?.facility
            ? { id: data.facility?.id, name: data.facility?.name }
            : undefined
        }
        refetchQueries={[FACILITY_DOCUMENTS]}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        sortModel={sortModel}
        setSortModel={setSortModel}
        deleteDocument={true}
      />
    </Stack>
  );
};
