import { ApolloCache, useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import {
  Document,
  DocumentAssociationsInput,
  DocumentInput2,
} from "generated-graphql/graphql";
import { useCallback, useMemo } from "react";

export const UPSERT_DOCUMENT_MUTATION = gql(`
  mutation UpsertDocument(
    $id: ID,
    $document: DocumentInput2!,
    $associations: [DocumentAssociationsInput!],
    $removeAssociations: [DocumentAssociationsInput!],
    $currentSubmissionDocument: Boolean!
  ) {
    upsertDocument(
      id: $id,
      document: $document,
      associations: $associations,
      removeAssociations: $removeAssociations
      currentSubmissionDocument: $currentSubmissionDocument
    ) {
      id
    }
  }
`);

export function useUpsertDocument(config?: {
  update?: (cache: ApolloCache<any>, { data }: { data: any }) => void;
}) {
  const [upsertDocumentMutation, { loading, error, data }] = useMutation(
    UPSERT_DOCUMENT_MUTATION,
    {
      update(cache, { data }) {
        config?.update?.(cache, { data });
      },
    }
  );

  const upsertDocument = useCallback(
    async ({
      documentData,
      associations,
      currentSubmissionDocument,
    }: {
      documentData: Document | DocumentInput2;
      currentSubmissionDocument: boolean;
      associations?: DocumentAssociationsInput[];
    }): Promise<string | undefined> => {
      if (!documentData.tenantId) {
        throw new Error("Tenant ID is required");
      }

      const result = await upsertDocumentMutation({
        variables: {
          document: documentData,
          associations,
          currentSubmissionDocument,
        },
      });

      return result.data?.upsertDocument.id;
    },
    [upsertDocumentMutation]
  );

  return useMemo(
    () => ({
      data,
      upsertDocument,
      loading,
      error,
      upsertDocumentMutation,
    }),
    [data, upsertDocument, loading, error, upsertDocumentMutation]
  );
}
