import Add from "@mui/icons-material/Add";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { HmbpSection } from "encamp-shared/src/hmbp";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useMemo, useState } from "react";
import { hasCriticalIssues } from "util/forms";
import { useHmbpUSTanks, useUpsertUndergroundStorageTank } from "../../useHmbp";
import { useReport } from "../../useReport";
import { useUndergroundStorageTankInputValidation } from "../../validationHooks/useUndergroundStorageTankValidation";
import { TankSection } from "./tanks";

export const UndergroundStorageTanks = () => {
  const tanks = useHmbpUSTanks();
  const { data } = useReport();
  const facilityId = data?.tierIIReport.facility.id ?? "";

  const [addTankOpen, setAddTankOpen] = useState(false);
  const openAddTank = useCallback(() => setAddTankOpen(true), []);
  const closeAddTank = useCallback(() => setAddTankOpen(false), []);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="caption">
          Required set of forms per each UST at facility
        </Typography>
        <Button startIcon={<Add />} onClick={openAddTank}>
          Add Tank
        </Button>
      </Stack>
      <Stack spacing={3}>
        {tanks.map((tank) => (
          <TankSection key={tank.id} tank={tank} />
        ))}
      </Stack>
      {addTankOpen && (
        <AddTankDialog facilityId={facilityId} onClose={closeAddTank} />
      )}
    </>
  );
};

const AddTankDialog = ({
  facilityId,
  onClose,
}: {
  facilityId: string;
  onClose: () => void;
}) => {
  const alerts = useAlerts();
  const { upsert, loading } = useUpsertUndergroundStorageTank();
  const save = useCallback(
    async (data: { tankId: string; nickname: string }) => {
      await upsert({ ...data, facilityId });
      onClose();
      alerts.success(
        "New UST Added - see bottom of Underground Storage Tanks (UST) section for required forms"
      );
    },
    [alerts, facilityId, onClose, upsert]
  );

  const defaultValues = useMemo(
    () => ({ tankId: "", nickname: "", facilityId }),
    [facilityId]
  );
  const form = useValidatingForm<{
    tankId: string;
    nickname: string;
    facilityId: string;
  }>(
    defaultValues,
    [],
    useUndergroundStorageTankInputValidation(HmbpSection.TankInformation)
  );

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Add Tank</DialogTitle>
      <DialogContent>
        <Box py={1}>
          <form>
            <FormTextField
              label="Tank Nickname *"
              name="nickname"
              control={form.control}
            />
            <FormTextField
              label="Tank ID *"
              name="tankId"
              control={form.control}
            />
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <SaveButton
          loading={loading}
          disabled={
            hasCriticalIssues(form.issues) ||
            !form.getValues("nickname") ||
            !form.getValues("tankId")
          }
          onClick={form.handleSubmit(save)}
        />
      </DialogActions>
    </Dialog>
  );
};
