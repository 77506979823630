import { DynamicField } from "generated-graphql/graphql";

import { Issue } from "generated-graphql/graphql";
import { DocumentOption, DialogStep, FormState, SuppliedIn } from "./types";
import { useCallback, useMemo, useState } from "react";
import { useValidatingForm } from "hooks/useValidatingForm";
import Close from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  Stack,
  IconButton,
  DialogContent,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { DocumentOptions } from "./DocumentOptions";
import { Upload } from "./Upload";
import { Exempt } from "./Exempt";
import { ProvidedElsewhere } from "./ProvidedElsewhere";
import { FormSubmitData } from "./types";
import { useReport } from "../../../useReport";

type HmbpDocumentFormProps = {
  title: string;
  issues: Issue[];
  fields: DynamicField[];
  loading?: boolean;
  handleClose: () => void;
  handleSave: (data: FormSubmitData) => Promise<void>;
  validator: (fields: DynamicField[]) => Promise<Issue[]>;
};

function calculateStep(documentOption: DocumentOption | null) {
  if (!documentOption) {
    return DialogStep.DocumentOptions;
  }

  return documentOption === DocumentOption.Upload
    ? DialogStep.Upload
    : documentOption === DocumentOption.ProvideElsewhere
    ? DialogStep.ProvidedElsewhere
    : DialogStep.Exempt;
}

const fieldKeys = [
  "documentOption",
  "suppliedIn",
  "suppliedInExplanation",
  "exemptExplanation",
  "documentIds",
] as const;

export function HmbpDocumentForm(props: HmbpDocumentFormProps) {
  const { data } = useReport();
  const facilityId = data?.tierIIReport?.facility?.id ?? "";

  const formFields = useMemo(
    () => createFieldMapping(props.fields, fieldKeys),
    [props.fields]
  );

  const genericIssues = useMemo(
    () => translateToGenericIssues(props.issues, formFields),
    [props.issues, formFields]
  );
  const [step, setStep] = useState(() =>
    calculateStep(formFields.documentOption?.value)
  );

  const defaultFormValues: FormState = useMemo(
    () => ({
      documentOption: formFields.documentOption?.value ?? null,
      suppliedIn: formFields.suppliedIn?.value as SuppliedIn | null,
      suppliedInExplanation: formFields.suppliedInExplanation?.value ?? null,
      exemptExplanation: formFields.exemptExplanation?.value ?? null,
      documentIds: formFields.documentIds?.value ?? [],
      upload: formFields.upload?.value ?? [],
    }),
    [formFields]
  );

  const validationCallback = useCallback(
    async (form: FormState) => {
      const updatedFields = updateFieldsWithFormData(props.fields, formFields, {
        ...form,
        documentIds: form.upload?.map((upload) => upload.document?.id) ?? [],
      });
      const nonGenericIssues = await props.validator(updatedFields);
      const genericIssues = translateToGenericIssues(
        nonGenericIssues,
        formFields
      );
      return genericIssues;
    },
    [props, formFields]
  );

  const form = useValidatingForm<FormState>(
    defaultFormValues,
    genericIssues,
    validationCallback
  );

  const { reset, watch } = form;

  const onSubmit = async (data: FormState) => {
    try {
      await props.handleSave({
        documentUploads: data.upload ?? [],
        stateFields: updateFieldsWithFormData(props.fields, formFields, data),
      });
      props.handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const documentOption = watch("documentOption");

  const handleNext = useCallback(() => {
    if (documentOption !== defaultFormValues.documentOption) {
      reset(
        {
          documentOption,
          suppliedIn: null,
          suppliedInExplanation: null,
          exemptExplanation: null,
          documentIds: [],
          upload: [],
        },
        { keepErrors: true }
      );
    }
    setStep(calculateStep(documentOption));
  }, [defaultFormValues.documentOption, documentOption, reset]);

  const handleBack = useCallback(() => {
    setStep(DialogStep.DocumentOptions);
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {props.title}
          <IconButton onClick={props.handleClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            {step === DialogStep.DocumentOptions && (
              <DocumentOptions
                handleClose={props.handleClose}
                handleNext={handleNext}
              />
            )}
            {step === DialogStep.Upload && (
              <Upload
                loading={props.loading}
                facilityId={facilityId}
                handleBack={handleBack}
              />
            )}
            {step === DialogStep.ProvidedElsewhere && (
              <ProvidedElsewhere
                loading={props.loading}
                handleBack={handleBack}
              />
            )}
            {step === DialogStep.Exempt && (
              <Exempt loading={props.loading} handleBack={handleBack} />
            )}
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

type FieldMapping = Record<string, { fieldKey: string; value: any }>;

export function getFieldByGenericKey(fields: DynamicField[], key: string) {
  return fields.find((field) =>
    field.key.toLowerCase().endsWith(key.toLowerCase())
  );
}

export function createFieldMapping(
  fields: DynamicField[],
  genericKeys: readonly string[]
): FieldMapping {
  return genericKeys.reduce((acc, key) => {
    const field = getFieldByGenericKey(fields, key);
    if (field) {
      acc[key] = {
        fieldKey: field.key,
        value: field.value,
      };
    }
    return acc;
  }, {} as FieldMapping);
}

export function translateToGenericIssues(
  issues: Issue[],
  fieldMapping: FieldMapping
): Issue[] {
  return issues.map((issue) => {
    const matchingField = Object.entries(fieldMapping).find(
      ([_, value]) => value.fieldKey === issue.key
    );
    return matchingField ? { ...issue, key: matchingField[0] } : issue;
  });
}

export function updateFieldsWithFormData(
  fields: DynamicField[],
  fieldMapping: FieldMapping,
  formData: Record<string, any>
): DynamicField[] {
  return fields.map((field) => {
    const formField = Object.entries(fieldMapping).find(
      ([_, value]) => value.fieldKey === field.key
    );
    return formField ? { ...field, value: formData[formField[0]] } : field;
  });
}
