import { FormControl, SxProps } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";

type Props<T extends FieldValues> = {
  label: string;
  tooltip?: string;
  datePickerProps?: DatePickerProps<DateTime>;
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
  hideErrors?: boolean;
  helperText?: string;
} & UseControllerProps<T>;

export function DateField<T extends FieldValues>(props: Props<T>) {
  const labelWithTooltip = useMemo(
    () =>
      props.tooltip ? (
        <TextWithTooltip text={props.label} tooltip={props.tooltip} />
      ) : (
        props.label
      ),
    [props.label, props.tooltip]
  );

  return (
    <FormControl fullWidth>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <DatePicker
                sx={props.sx}
                {...props.datePickerProps}
                label={labelWithTooltip}
                value={value ? DateTime.fromISO(value) : null}
                onChange={(newValue) => onChange(newValue?.toISODate())}
                disabled={props.disabled}
                slotProps={{
                  textField: {
                    error: !!error,
                    required: props.required,
                  },
                }}
              />
              <ErrorDisplay
                error={error}
                helperText={props.helperText}
                hideErrors={props.hideErrors}
              />
            </>
          );
        }}
      />
    </FormControl>
  );
}
