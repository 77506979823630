import { Frequency, Task, Tag } from "@prisma/client";
import { DateTime } from "luxon";
import {
  RRule,
  Frequency as RRuleFrequency,
  Options as RRuleOptions,
  RRuleSet,
} from "rrule";
import { ExpandSeriesOptions, VirtualEventStatus } from "./types";
import { TaskTag } from "../prisma/generated/schema";

export const BEGINNING_OF_TIME = new Date("1970-01-01");
export const END_OF_TIME = new Date("9999-12-31");

// Pass an anonymous function which can filter the virtual events
// Also pass in the count of desired elements, so we don't have to expand until the end of time.
// that only works if we have sort by deadline. Consider if we wanted to sort by deadline_desc, that makes no sense with
// an infinite recurrence. Or, if i wanted to sort by title, then i'd (oddly) only ever see 1 task if it's infinite with a title before others.
export function expandSeries<
  T extends Task & { taskTags?: Partial<TaskTag> & { tag: Tag }[] }
>(tasks: T[], options: ExpandSeriesOptions) {
  return tasks.flatMap((task) => {
    const rrule = getRRuleFromTask(task);
    const dates = rruleAll(rrule, options);
    return dates.map((date) => {
      const { id, ...rest } = task;

      return {
        ...rest,
        id: getVirtualEventId(id, date),
        taskId: id,
        deadline: date,
        status: getVirtualEventStatus({
          deadline: date,
          completedAt: task.completedAt,
          now: options.now,
        }),
        tags: task.taskTags?.map((tt) => tt.tag) ?? [],
      };
    });
  });
}

function getRRuleFromTask(task: Task) {
  const rrule = new RRuleSet(true);
  rrule.rrule(new RRule(dbTaskToRRuleOptions(task)));
  task.excludedDates.forEach((d) => rrule.exdate(d));
  return rrule;
}

function rruleAll(rrule: RRuleSet, { after, before }: ExpandSeriesOptions) {
  const effectiveAfter = new Date(after ?? BEGINNING_OF_TIME);
  const effectiveBefore = new Date(
    before ?? DateTime.now().plus({ years: 10 }).toJSDate()
  );
  return rrule.between(effectiveAfter, effectiveBefore, true);
}

export function dbTaskToRRuleOptions(
  task: Pick<Task, "frequency" | "interval" | "startDate" | "endDate">
): Partial<RRuleOptions> {
  const opts = mapFrequencyAndInterval(task);
  const startDate = task.startDate;

  if (
    startDate.getUTCDate() >= 28 &&
    (task.frequency === Frequency.MONTHLY ||
      task.frequency === Frequency.QUARTERLY)
  ) {
    // Get the day of month from the start date
    const startDay = startDate.getUTCDate();
    // Create array starting at 28 up to startDay (inclusive)
    const bymonthday = [28, 29, 30, 31].slice(0, startDay - 27);

    return {
      ...opts,
      bymonthday,
      bysetpos: -1,
      dtstart: startDate,
      until: task.endDate,
    };
  }
  return {
    ...opts,
    dtstart: task.startDate,
    until: task.endDate,
  };
}

function mapFrequencyAndInterval(
  task: Pick<Task, "frequency" | "interval">
): Pick<RRuleOptions, "freq" | "interval"> | undefined {
  switch (task.frequency) {
    case Frequency.WEEKLY:
      return { freq: RRuleFrequency.WEEKLY, interval: task.interval };
    case Frequency.MONTHLY:
      return { freq: RRuleFrequency.MONTHLY, interval: task.interval };
    case Frequency.QUARTERLY:
      return { freq: RRuleFrequency.MONTHLY, interval: task.interval * 3 };
    case Frequency.YEARLY:
      return { freq: RRuleFrequency.YEARLY, interval: task.interval };
    case Frequency.ONCE:
    case null:
    default:
      return undefined;
  }
}

export function getVirtualEventId(taskId: string, deadline: Date) {
  return `${taskId}_${deadline.toISOString()}`;
}

export function parseVirtualEventId(eventId: string) {
  const [taskId, deadlineStr] = eventId.split("_");
  return {
    taskId,
    deadline: new Date(deadlineStr),
  };
}

export function prevDate(task: Task, date: Date) {
  const rrule = getRRuleFromTask(task);
  return rrule.before(date, false);
}

export function nextDate(task: Task, date: Date) {
  const rrule = getRRuleFromTask(task);
  return rrule.after(date, false);
}

function getVirtualEventStatus(task: {
  completedAt?: Date | null;
  deadline: Date;
  /**
   * Optional time for now
   * @default new Date()
   */
  now?: Date;
}): VirtualEventStatus {
  if (task.completedAt) {
    return VirtualEventStatus.Completed;
  }
  return new Date(task.deadline) < (task.now ?? new Date())
    ? VirtualEventStatus.Overdue
    : VirtualEventStatus.Active;
}
