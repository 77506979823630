import CheckCircle from "@mui/icons-material/CheckCircle";
import Upload from "@mui/icons-material/Upload";
import { Button, CircularProgress } from "@mui/material";
import {
  Document,
  DocumentType,
  UploadedDocumentQuery,
} from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDocumentUpload } from "hooks/useDocumentUpload";
import React, { useCallback, useRef } from "react";
import invariant from "tiny-invariant";
import { useAlerts } from "../../../../components/Alerts/AlertProvider";

type FileUploadProps = {
  tenantId: string;
  label: string;
  documentType?: DocumentType;
  startIcon?: React.ReactNode;
  facilityId?: string;
  reportId?: string;
  activityId?: string;
  description?: string;
  state?: string | null;
  onSuccess?: (document: Document) => unknown;
  onError?: (error: unknown) => unknown;
  sx?: React.CSSProperties;
  acceptedFileTypes?: string;
};

const DocumentUpload = ({
  tenantId,
  facilityId,
  reportId,
  activityId,
  state,
  description,
  label,
  startIcon,
  documentType = DocumentType.Other,
  onSuccess,
  onError,
  sx = {},
  acceptedFileTypes = "",
}: FileUploadProps): JSX.Element => {
  const alerts = useAlerts();
  const { user, loading: userLoading } = useCurrentUser();

  const translatingOnSuccess = useCallback(
    (document: UploadedDocumentQuery["document"]) =>
      onSuccess?.(document as Document),
    [onSuccess]
  );
  const handleError = useCallback(
    (err: unknown) => {
      alerts.error(`An error occurred while uploading the document`);
      console.error(`An error occurred while uploading the document`, {
        err,
      });
      onError?.(err);
    },
    [alerts, onError]
  );

  const { uploadFile, isUploading, uploadComplete } = useDocumentUpload({
    onSuccess: translatingOnSuccess,
    onError: handleError,
  });

  const fileInput = useRef<HTMLInputElement>(null);

  if (userLoading) {
    return <></>;
  }

  invariant(user);

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        uploadFile({
          file,
          documentData: {
            tenantId,
            state,
            title: file.name ?? description, // keep the extension in the title
            documentType,
          },
          currentSubmissionDocument: false,
          associations: [{ tenantId, facilityId, reportId, activityId }],
        });
      } catch (err) {
        alerts.error(`${err}`);
        if (onError) {
          onError(err);
        }
      } finally {
        // Clear the file input value - this is so we can upload the same file
        // again if we need to, since it won't let you normally
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      }
    }
  };

  return (
    <Button
      variant="contained"
      size="small"
      sx={sx}
      startIcon={
        isUploading ? (
          <CircularProgress size={16} />
        ) : uploadComplete ? (
          <CheckCircle />
        ) : (
          startIcon || <Upload />
        )
      }
      component="label"
      disabled={isUploading || uploadComplete}
    >
      {uploadComplete ? "Upload Complete" : label}
      <input
        ref={fileInput}
        accept={acceptedFileTypes}
        type="file"
        onChange={handleFileChange}
        hidden
      />
    </Button>
  );
};

export default DocumentUpload;
