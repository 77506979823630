import { UndergroundStorageTankInput } from "generated-graphql/graphql";

export function transform(
  input: UndergroundStorageTankInput
): UndergroundStorageTankInput {
  return {
    ...input,
    capacityGallons: input.capacityGallons
      ? Number(input.capacityGallons)
      : null,
    numberOfCompartments: input.numberOfCompartments
      ? Number(input.numberOfCompartments)
      : null,
  };
}
