import { FacilityDocumentPickerItem } from "components/FacilityDocumentPicker";
import { DynamicField } from "generated-graphql/graphql";

export enum DocumentOption {
  Upload = "Upload",
  ProvideElsewhere = "Provided Elsewhere",
  Exempt = "Exempt",
}

export enum SuppliedIn {
  FacilityInformation = "FacilityInformation",
  UndergroundStorageTanks = "UndergroundStorageTanks",
  TieredPermitting = "TieredPermitting",
  RecyclableMaterialsReport = "RecyclableMaterialsReport",
  RemoteWasteConsolidationSiteAnnualNotification = "RemoteWasteConsolidationSiteAnnualNotification",
  HazardousWasteTankClosureCertification = "HazardousWasteTankClosureCertification",
  AbovegroundPetroleumStorageAct = "AbovegroundPetroleumStorageAct",
  CaliforniaAccidentalReleaseProgram = "CaliforniaAccidentalReleaseProgram",
}

export enum DialogStep {
  DocumentOptions = "document-options",
  Upload = "upload",
  ProvidedElsewhere = "provided-elsewhere",
  Exempt = "exempt",
}

export type FormState = {
  documentOption: DocumentOption | null;
  suppliedIn: SuppliedIn | null;
  suppliedInExplanation: string | null;
  exemptExplanation: string | null;
  documentIds: string[];
  upload?: UploadFile[];
};

export type FormSubmitData = {
  documentUploads: UploadFile[];
  stateFields: DynamicField[];
};

export type UploadFile = {
  document?: FacilityDocumentPickerItem;
  file?: File;
  extension: string;
  previewURL: string | null;
};
