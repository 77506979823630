import { useMutation, useQuery } from "@apollo/client";
import {
  HmbpMetadata,
  HmbpSection,
  HmbpSectionWithoutRegulatory,
  HmbpUndergroundStorageTankSection,
  USTContents,
} from "encamp-shared/src/hmbp";
import { gql } from "generated-graphql";
import {
  DocumentType,
  DynamicField,
  Issue,
  ProgramArea,
  TierIiReportCaStep,
  TierIiReportStep,
  UndergroundStorageTank,
  UndergroundStorageTankInput,
} from "generated-graphql/graphql";
import { transform as transformStateField } from "hooks/transform/transformStateField";
import { transform } from "hooks/transform/transformUndergroundStorageTank";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { Path } from "react-hook-form";
import { ReportStepState } from "util/constants";
import {
  GET_REPORT,
  Report,
  useReport,
  useReportDynamicFields,
  useReportIssues,
} from "./useReport";
import { useTouchReportMutation } from "./useTouchReportMutation";
import { useFacilityInputValidation } from "./validationHooks/useFacilityInputValidation";

gql(`
  fragment undergroundStorageTank on UndergroundStorageTank {
    id
    facilityId
    tankId
    nickname

    typeOfAction
    manufacturer
    capacityGallons
    installationDate
    existingDiscoveredDate
    permanentlyClosedDate
    configuration
    numberOfCompartments
    additionalDescription
    tankUsage
    tankContents
    type
    constructionPrimaryContainment
    constructionSecondaryContainment
    overfillProtection
    pipingConstruction
    pipingSystemType
    productWastePrimaryContainment
    productWasteSecondaryContainment
    pipingTurbineContainmentSump
    ventPrimaryContainment
    ventSecondaryContainment
    vaporRecoveryPrimaryContainment
    vaporRecoverySecondaryContainment
    riserPipePrimaryContainment
    riserPipeSecondaryContainment
    ventPipingTransitionSumps
    fillComponentsInstalled
    constructionType
    constructionMaterial
    corrosionProtection
    
    monitoringEquipmentServiced
    sitePlotPlanSubmitted
    tankMonitoringMethod
    pipeMonitoringMethod
    udcMonitoring
    udcPanelManufacturer
    udcPanelModel
    udcLeakSensorManufacturer
    udcLeakSensorModel
    hasUdcLeakDetection
    hasUdcLeakAlarmTriggerShutdown
    hasUdcFailureTriggerShutdown
    hasUdcMonitoringStopFlow
    udcConstruction
    udcSecondaryContactMonitoring
    hasUdcSecondaryLeakDetection
    periodicSystemTesting
    recordKeeping
    ustPersonnelFamiliarTraining
    otherTrainingDocuments
    designatedOperatorTraining
    additionalInformation
    firstNameResponsibility
    firstNameTitle
    secondNameResponsibility
    secondNameTitle

    previouslyReported
  }
`);

export type HmbpIssue = Issue & {
  metadata: HmbpMetadata;
};

export type HmbpDynamicField = DynamicField & {
  metadata: HmbpMetadata;
};

type HmbpOptions = {
  section?: HmbpSection;
  omit?: HmbpSection[];
};

function isHmbpType<T extends { metadata?: { hmbpSection?: HmbpSection } }>(
  item: T,
  options?: HmbpOptions
): item is T & { metadata: HmbpMetadata } {
  const hmbpSection = item.metadata?.hmbpSection;
  return options?.section
    ? hmbpSection === options.section
    : hmbpSection !== undefined && !options?.omit?.includes(hmbpSection);
}

export function isHmbpIssue(
  issue: Issue,
  options: HmbpOptions
): issue is HmbpIssue {
  return isHmbpType(issue, options);
}

export function isHmbpDynamicField(
  field: DynamicField,
  options: HmbpOptions
): field is HmbpDynamicField {
  return isHmbpType(field, options);
}

export const hmbpDocumentTypes: Partial<
  Record<HmbpSectionWithoutRegulatory, DocumentType>
> = {
  [HmbpSection.AbovegroundPetroleumStorageActDocumentation]:
    DocumentType.AboveGroundPetroleumStorageActDocumentation,
  [HmbpSection.EmergencyResponseContingencyPlan]:
    DocumentType.EmergencyResponsePlan,
  [HmbpSection.EmployeeTrainingPlan]: DocumentType.TrainingPlan,
  [HmbpSection.HazardousWasteTankClosureCertificate]:
    DocumentType.HazardousWasteTankClosureCertificate,
  [HmbpSection.OwnerStatementOfDesignatedUSTOperatorCompliance]:
    DocumentType.OwnerStatementOfDesignatedUstOperatorCompliance,
  [HmbpSection.RecyclableMaterialsReportDocumentation]:
    DocumentType.RecyclableMaterialsReportDocumentation,
  [HmbpSection.USTCertificationOfFinancialResponsibility]:
    DocumentType.UstCertificationOfFinancialResponsibility,
  [HmbpSection.USTLetterFromChiefFinancialOfficer]:
    DocumentType.UstLetterFromChiefFinancialOfficer,
  [HmbpSection.USTOwnerOperatorWrittenAgreement]:
    DocumentType.UstOwnerOperatorWrittenAgreement,
  [HmbpSection.USTMonitoringSitePlan]: DocumentType.UstMonitoringSitePlan,
  [HmbpSection.USTResponsePlan]: DocumentType.UstResponsePlan,
};

export function useHmbpFeature(state: string) {
  const { featureFlags } = useFeatureFlags();

  return useMemo(() => {
    return featureFlags?.hmbp && state === "CA";
  }, [state, featureFlags]);
}

// returns all issues that have an hmbpSection metadata field, optionally filtered by the section
export function useHmbpIssues(
  section?: HmbpSectionWithoutRegulatory
): HmbpIssue[] {
  const reportIssues = useReportIssues();
  return useMemo(
    () =>
      reportIssues.filter((issue) =>
        isHmbpIssue(issue, { section, omit: [HmbpSection.Regulatory] })
      ),
    [reportIssues, section]
  );
}

// returns all issues belonging to the Regulatory section
export function useHmbpRegulatoryIssues(): HmbpIssue[] {
  const reportIssues = useReportIssues();
  return useMemo(
    () =>
      reportIssues.filter((issue) =>
        isHmbpIssue(issue, { section: HmbpSection.Regulatory })
      ),
    [reportIssues]
  );
}

// returns all dynamic fields that have an hmbpSection metadata field, optionally filtered by the section
export function useHmbpDynamicFields(
  section?: HmbpSectionWithoutRegulatory
): HmbpDynamicField[] {
  const reportFields = useReportDynamicFields();
  return useMemo(
    () =>
      reportFields.filter((field) =>
        isHmbpDynamicField(field, { section, omit: [HmbpSection.Regulatory] })
      ),
    [reportFields, section]
  );
}

export function useShowSection(section: HmbpSectionWithoutRegulatory): boolean {
  const stateFields = useReportDynamicFields();
  const usTanks = useHmbpUSTanks();
  switch (section) {
    case HmbpSection.RecyclableMaterialsReportDocumentation:
      return stateFields.find(
        (field) => field.key === "hasExcludedExemptedMaterials"
      )?.value;
    case HmbpSection.USTMonitoringSitePlan:
      return stateFields.find((field) => field.key === "hasUST")?.value;
    case HmbpSection.USTCertificationOfFinancialResponsibility:
      return (
        stateFields.find((field) => field.key === "hasUST")?.value &&
        usTanks
          .map((tank) => tank.tankContents)
          .some((content) =>
            [
              USTContents["Regular Unleaded"],
              USTContents["Premium Unleaded"],
              USTContents["Midgrade Unleaded"],
              USTContents["Diesel"],
              USTContents["Jet Fuel"],
              USTContents["Aviation Gas"],
              USTContents["Other Petroleum"],
            ].includes(content as USTContents)
          )
      );
    default:
      return true;
  }
}

// returns all dynamic fields belonging to the Regulatory section
export function useHmbpRegulatoryDynamicFields(): HmbpDynamicField[] {
  const reportFields = useReportDynamicFields();
  return useMemo(
    () =>
      reportFields.filter((field) =>
        isHmbpDynamicField(field, { section: HmbpSection.Regulatory })
      ),
    [reportFields]
  );
}

export function useHmbpUSTanks(): UndergroundStorageTank[] {
  const { data } = useReport();
  return data?.tierIIReport.facility.undergroundStorageTanks ?? [];
}

export function useHmbpUSTankIssues(
  section: HmbpUndergroundStorageTankSection,
  modelId: string
): Issue[] {
  return useReportIssues().filter(
    (issue) =>
      isHmbpIssue(issue, { section, omit: [HmbpSection.Regulatory] }) &&
      issue.modelId === modelId
  );
}

export function useHmbpUSTankStatus(
  section: HmbpUndergroundStorageTankSection,
  modelId: string
): ReportStepState {
  const issues = useHmbpUSTankIssues(section, modelId);
  const { data } = useReport();

  const touchedSection = data?.tierIIReport.undergroundStorageTankStatuses
    ?.find(({ undergroundStorageTankId }) => {
      undergroundStorageTankId === modelId;
    })
    ?.touchedSteps.includes(section);

  if (issues.length) {
    return ReportStepState.Invalid;
  }

  if (touchedSection) {
    return ReportStepState.Done;
  }

  return ReportStepState.NotStarted;
}

// A shared validator to pass to useValidatingForm that will validate the facility state fields
export function useHmbpValidator(section: HmbpSectionWithoutRegulatory) {
  const { data } = useReport();

  const validateFacility = useFacilityInputValidation({
    facilityId: data?.tierIIReport.facility.id ?? "",
    reportId: data?.tierIIReport.id,
    pick: ["stateFields"],
  });

  return useCallback(
    async (facilityStateFields: DynamicField[]) => {
      const issues = await validateFacility({
        fireDepartmentId: data?.tierIIReport.facility.fireDepartmentId,
        lepcId: data?.tierIIReport.facility.lepcId,
        state: data?.tierIIReport.facility.state,
        stateFields: facilityStateFields.map((field) => ({
          ...field,
          type: ProgramArea.Epcra,
        })),
      });
      return issues.filter((issue) => isHmbpIssue(issue, { section }));
    },
    [
      data?.tierIIReport.facility.fireDepartmentId,
      data?.tierIIReport.facility.lepcId,
      data?.tierIIReport.facility.state,
      section,
      validateFacility,
    ]
  );
}

const UPDATE_FACILITY_STATE_FIELDS = gql(`
  mutation UpdateFacilityStateFields($facilityId: ID!, $stateFields: [DynamicFieldInput!]!) {
    updateFacilityStateFields(facilityId: $facilityId, stateFields: $stateFields) {
      id
    }
  }
`);
export function useCAStepStatus(step: TierIiReportCaStep): ReportStepState {
  const { data } = useReport();
  const issues = useHmbpIssues(step as unknown as HmbpSectionWithoutRegulatory);
  if (!data) return ReportStepState.NotStarted;
  return getCAStepStatus(step, data.tierIIReport, issues);
}

function getCAStepStatus(
  step: TierIiReportCaStep,
  report: Report,
  issues: Issue[]
): ReportStepState {
  return issues?.length
    ? ReportStepState.Invalid
    : report.touchedCASteps?.includes(step)
    ? ReportStepState.Done
    : ReportStepState.NotStarted;
}

// A shared mutation for saving state fields
export function useHmbpStateFieldMutation() {
  const { data } = useReport();

  const [updateFacilityStateFields, { loading: submitting }] = useMutation(
    UPDATE_FACILITY_STATE_FIELDS
  );

  // We'll want to touch the report on any change
  // TODO: we'll probably want to do something similar for the HMBP steps.
  // Maybe useTouchReportMutation can accept an optional hmbp step to touch?
  const { handleSave: handleTouch, loading: touchLoading } =
    useTouchReportMutation(
      data?.tierIIReport.id ?? "",
      data?.tierIIReport.touchedSteps ?? [],
      TierIiReportStep.StateInformation,
      false // don't navigate to overview after saving state fields
    );

  const mutation = useCallback(
    async (stateFields: DynamicField[]) => {
      if (!data) return;

      await updateFacilityStateFields({
        variables: {
          facilityId: data.tierIIReport.facility.id,
          stateFields: stateFields.map(transformStateField),
        },
        awaitRefetchQueries: true,
      });
      await handleTouch();
    },
    [data, handleTouch, updateFacilityStateFields]
  );

  return useMemo(
    () => ({
      mutation,
      loading: submitting || touchLoading,
    }),
    [mutation, submitting, touchLoading]
  );
}

const REMOVE_UNDERGROUND_STORAGE_TANK = gql(`
  mutation RemoveUndergroundStorageTank($id: ID!) {
    removeUndergroundStorageTank(id: $id) {
      ...undergroundStorageTank
    }
  }
`);

export const useRemoveUndergroundStorageTank = () => {
  const [mutate, { loading }] = useMutation(REMOVE_UNDERGROUND_STORAGE_TANK);

  const remove = useCallback(
    async (id: string) => {
      await mutate({
        variables: { id },
        refetchQueries: [GET_REPORT],
      });
    },
    [mutate]
  );

  return { remove, loading };
};

const UPSERT_UNDERGROUND_STORAGE_TANK = gql(`
  mutation UpsertUndergroundStorageTank($input: UndergroundStorageTankInput!) {
    upsertUndergroundStorageTank(input: $input) {
      ...undergroundStorageTank
    }
  }
`);

export const useUpsertUndergroundStorageTank = () => {
  const [mutate, { loading }] = useMutation(UPSERT_UNDERGROUND_STORAGE_TANK);

  const upsert = useCallback(
    async (data: UndergroundStorageTankInput) => {
      await mutate({
        variables: {
          input: transform({ ...data }),
        },
        refetchQueries: [GET_REPORT],
      });
    },
    [mutate]
  );
  return { upsert, loading };
};

const UNDERGROUND_STORAGE_TANK_FIELDS = gql(`
  query UndergroundStorageTankFields($jurisdictions: [String!]) {
    undergroundStorageTankFields(jurisdictions: $jurisdictions) {
      ...dynamicField
      group
      helperText
    }
  }
`);

export const useUndergroundStorageTankFields = (): DynamicField[] => {
  const { data: reportData, loading: reportLoading } = useReport();
  const jurisdictions = compact([
    reportData?.tierIIReport.facility.state,
    reportData?.tierIIReport.facility.lepcId,
    reportData?.tierIIReport.facility.fireDepartmentId,
  ]);
  const query = useQuery(UNDERGROUND_STORAGE_TANK_FIELDS, {
    variables: { jurisdictions },
    skip: reportLoading,
  });

  const { data } = query;
  return data?.undergroundStorageTankFields ?? [];
};

type UndergroundStorageTankDynamicField = HmbpDynamicField & {
  key: Path<UndergroundStorageTankInput>;
};

export function useUndergroundStorageTankDynamicFields(
  section: HmbpUndergroundStorageTankSection
): UndergroundStorageTankDynamicField[] {
  const fields = useUndergroundStorageTankFields()
    .filter((field) => isHmbpDynamicField(field, { section }))
    .map((field) => ({
      ...field,
      key: field.key as Path<UndergroundStorageTankInput>,
    }));
  return fields;
}
