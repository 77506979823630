import {
  DocumentInput,
  FacilityDocumentInput,
} from "generated-graphql/graphql";
import { omit } from "lodash";

export function transform(input: DocumentInput): DocumentInput {
  return {
    ...omit(
      input,
      "id",
      "lobId",
      "owner",
      "fileSize",
      "createdAt",
      "createdBy",
      "updatedAt",
      "updatedBy",
      "relatedEntityIds",
      "searchableProgramAreas",
      "downloadLink"
    ),
    facilities: input.facilities?.map<FacilityDocumentInput>(
      ({ id, name }) => ({ id, name })
    ),
  };
}
