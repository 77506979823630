import { useLazyQuery } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Download from "@mui/icons-material/Download";
import Edit from "@mui/icons-material/Edit";
import { Button, IconButton, Link, Stack, Typography } from "@mui/material";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
  EditMode,
  FormState,
} from "components/AddEditDocumentDialog";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useTenant } from "hooks/useTenant";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { useState } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { TaskFormType } from "./TaskDialog";

type Props = {
  control: Control<TaskFormType>;
  onDelete: (id: string) => void;
};

export const DocumentsControl = ({ control, onDelete }: Props) => {
  const alerts = useAlerts();
  const { tenantId } = useTenant();
  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>(defaultAddEditDocumentDialogState);
  const {
    fields: documents,
    remove,
    append,
    update,
  } = useFieldArray({
    control,
    name: "documents",
    keyName: "_id",
  });
  const handleDelete = (id: string) => {
    const index = documents.findIndex((document) => document.id === id);
    if (index !== -1) {
      remove(index);
      onDelete(id);
    }
  };
  const handleEdit = (document: TaskFormType["documents"][number]) => {
    setAddEditDialogState({
      ...defaultAddEditDocumentDialogState,
      open: true,
      mode: EditMode.EDIT,
      step: FormState.FORM,
      documentId: document.id,
      documentType: document.documentType,
      fileExtension: document.fileExtension,
      title: document.title,
    });
  };
  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          return alerts.error("Error fetching download link");
        }
        alerts.success("Success fetching download link");
        window.open(data.getDocumentDownloadLink, "_blank");
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Documents</Typography>
        {tenantId && (
          <Button
            startIcon={<Add />}
            variant="text"
            onClick={() => {
              setAddEditDialogState({
                ...defaultAddEditDocumentDialogState,
                open: true,
                mode: EditMode.ADD,
                step: FormState.UPLOAD,
              });
            }}
          >
            Add Documents
          </Button>
        )}
      </Stack>
      <Stack direction="column" spacing={1}>
        {documents?.length === 0 && (
          <Typography variant="body2">
            No documents have been added to this task yet
          </Typography>
        )}
        {documents.map((document) => (
          <DocumentItem
            key={document.id}
            document={document}
            onDelete={() => handleDelete(document.id)}
            onDownload={() =>
              getDocumentDownloadLink({ variables: { id: document.id } })
            }
            onEdit={() => handleEdit(document)}
          />
        ))}
      </Stack>
      {addEditDialogState.open && tenantId && (
        <AddEditDocumentDialog
          tenantId={tenantId}
          dialogState={addEditDialogState}
          setDialogState={setAddEditDialogState}
          showFacilityPicker={false}
          onSubmit={(document, dialogState) => {
            if (dialogState.mode === "Add") {
              append(document);
            } else {
              const index = documents.findIndex(
                (doc) => doc.id === dialogState.documentId
              );
              if (index !== -1) {
                update(index, document);
              }
            }
          }}
          refetchQueries={[]}
        />
      )}
    </>
  );
};

const DocumentItem = ({
  document,
  onDelete,
  onDownload,
  onEdit,
}: {
  document: TaskFormType["documents"][number];
  onDelete: () => void;
  onDownload: () => void;
  onEdit: () => void;
}) => {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="body2" width={"100%"}>
        <Link underline="hover" color="primary" onClick={onDownload}>
          {document.title}
        </Link>
      </Typography>
      <Stack direction="row" columnGap={1}>
        <IconButton edge="end" onClick={onDownload}>
          <Download />
        </IconButton>
        <IconButton edge="end" onClick={onEdit}>
          <Edit />
        </IconButton>
        <IconButton edge="end" onClick={onDelete}>
          <Delete />
        </IconButton>
      </Stack>
    </Stack>
  );
};
