import { Button, Stack, Typography, useTheme } from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { SaveButton } from "components/SaveButton";
import { useFormContext } from "react-hook-form";
import { SuppliedIn } from "./types";

type ProvidedElsewhereProps = {
  handleBack: () => void;
  loading?: boolean;
};

const SuppliedInLabels: Record<SuppliedIn, string> = {
  [SuppliedIn.FacilityInformation]: "Facility Information",
  [SuppliedIn.UndergroundStorageTanks]: "Underground Storage Tanks",
  [SuppliedIn.TieredPermitting]: "Tiered Permitting",
  [SuppliedIn.RecyclableMaterialsReport]: "Recyclable Materials Report",
  [SuppliedIn.RemoteWasteConsolidationSiteAnnualNotification]:
    "Remote Waste Consolidation Site Annual Notification",
  [SuppliedIn.HazardousWasteTankClosureCertification]:
    "Hazardous Waste Tank Closure Certification",
  [SuppliedIn.AbovegroundPetroleumStorageAct]:
    "Aboveground Petroleum Storage Act",
  [SuppliedIn.CaliforniaAccidentalReleaseProgram]:
    "California Accidental Release Program",
};

export function ProvidedElsewhere({
  loading,
  handleBack,
}: ProvidedElsewhereProps) {
  const theme = useTheme();
  const { control, watch } = useFormContext();

  const suppliedIn: SuppliedIn | null = watch("suppliedIn");
  const explanation: string | null = watch("explanation");

  return (
    <>
      <Typography variant="body1" marginBottom={theme.spacing(2)}>
        Provided Elsewhere in CERS
      </Typography>

      <Typography variant="body2" marginBottom={theme.spacing(2)}>
        If requirements for this supplemental documentation can be satisfied by
        another document you have provided in CERS, please indicate the
        submittal element where the document can be found and provide the
        submittal date or other comments to assist your regulator in locating
        this document in your current/previous CERS facility submittals.
      </Typography>

      <RadioGroupField
        control={control}
        name="suppliedIn"
        label="Supplied in Submittal Event"
        direction="column"
        radioOptions={Object.values(SuppliedIn).map((key) => ({
          value: key,
          label: SuppliedInLabels[key],
        }))}
      />

      <FormTextField
        control={control}
        name="suppliedInExplanation"
        label="Indicate submittal date and/or other explanation"
      />

      <Stack
        direction="row"
        gap={theme.spacing(2)}
        justifyContent="flex-end"
        sx={{ marginTop: theme.spacing(2) }}
      >
        <Button onClick={handleBack} variant="outlined">
          Back
        </Button>
        <SaveButton
          loading={loading ?? false}
          disabled={suppliedIn === null || explanation === null}
        />
      </Stack>
    </>
  );
}
