import { Reference, useMutation } from "@apollo/client";
import BlockIcon from "@mui/icons-material/Block";
import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import Error from "@mui/icons-material/Error";
import FlagIcon from "@mui/icons-material/Flag";
import HourglassTop from "@mui/icons-material/HourglassTop";
import Lock from "@mui/icons-material/Lock";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import QuestionMark from "@mui/icons-material/QuestionMark";
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded";
import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridActionsCellItem,
} from "@mui/x-data-grid-premium";
import { ConfirmDialog } from "components/ConfirmDialog";
import { FacilitiesExportButton } from "components/FacilitiesExportButton";
import { Importer } from "components/Importer";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  FacilityAlternateIdKnownKind,
  hasStateId,
  stateRequiresStateId,
} from "encamp-shared/src/facility/alternateId";
import { findAlternateId } from "encamp-shared/src/facilityAlternateId/findAlternateId";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { getUtcDisplayDate } from "encamp-shared/src/utils/date";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  CollectionMode,
  CredentialKind,
  CredentialVerificationStatus,
  FeatureFlagOverride,
  ImplementationFacilitiesQuery,
} from "generated-graphql/graphql";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { compact } from "lodash";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { credentialKindToLabel } from "util/constants";
import { prettyPrintDateTime } from "util/dates";
import { FacilitiesBulkActionsMenu } from "./FacilitiesBulkActionMenu";

const FACILITIES_WITH_JOBS_QUERY = gql(`
  query ImplementationFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        customerFacilityId
        name
        state
        collectionMode
        streetAddress1
        streetAddress2
        city
        zip
        state
        country
        wasteFeatureFlagOverride
        chemicalFeatureFlagOverride
        portalCredential {
          credential {
            id
            username
          }
        }
        tenant {
          id
          name
        }
        jobs(action: extractData, sortDirection: DESC) {
          id
          action
          status
          reportingYear
          requestId
          facilityId
          reportId
          createdAt
          updatedAt
        }
        facilityAlternateIds {
          id
          type
          value
          hasNoId
          expiresAt
        }
        facilityCredentials {
          id
          credential {
            id
            verificationStatus
            lastVerificationCheckAt
            kind
          }
        }
        issueCount
        updatedAt
        createdAt
      }
      count
    }
  }
`);

export type Row = ImplementationFacilitiesQuery["facilities"]["items"][number];

const DELETE_FACILITY_MUTATION = gql(`
  mutation DeleteFacility($id: ID!) {
    deleteFacility(id: $id) {
      id
    }
  }
`);

export function ImplementationFacilities({ tenantId }: { tenantId: string }) {
  const theme = useTheme();

  const { featureFlags } = useFeatureFlags();
  const isWasteEnabled = featureFlags?.["module-waste"] ?? false;
  const isTierIIEnabled = featureFlags?.["module-tierii"] ?? false;

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 0.75,
        minWidth: 150,
        maxWidth: 500,
      },
      {
        field: "state",
        headerName: "State",
        maxWidth: 50,
      },
      {
        field: "wasteFeatureFlagOverride",
        headerName: "Waste",
        type: "enum",
        headerAlign: "center",
        align: "center",
        filterKeyType: "enum",
        enumValues: Object.values(FeatureFlagOverride),
        renderCell({ row: { wasteFeatureFlagOverride: override } }) {
          if (override === FeatureFlagOverride.Enabled) {
            return (
              <Tooltip title="Waste is enabled">
                <Check />
              </Tooltip>
            );
          }

          if (override === FeatureFlagOverride.Disabled) {
            return (
              <Tooltip title="Waste is disabled">
                <BlockIcon />
              </Tooltip>
            );
          }

          if (override === FeatureFlagOverride.InheritLdFlagForTenant) {
            return (
              <Tooltip
                title={`Inheriting LaunchDarkly Module:Waste flag (${isWasteEnabled})`}
              >
                {isWasteEnabled ? <FlagIcon /> : <OutlinedFlagIcon />}
              </Tooltip>
            );
          }
          return null;
        },
        minWidth: 80,
      },
      {
        field: "chemicalFeatureFlagOverride",
        headerName: "Chemicals",
        type: "enum",
        headerAlign: "center",
        align: "center",
        filterKeyType: "enum",
        enumValues: Object.values(FeatureFlagOverride),
        renderCell({ row: { chemicalFeatureFlagOverride: override } }) {
          if (override === FeatureFlagOverride.Enabled) {
            return (
              <Tooltip title="Chemicals is enabled">
                <Check />
              </Tooltip>
            );
          }

          if (override === FeatureFlagOverride.Disabled) {
            return (
              <Tooltip title="Chemicals is disabled">
                <BlockIcon />
              </Tooltip>
            );
          }

          if (override === FeatureFlagOverride.InheritLdFlagForTenant) {
            return (
              <Tooltip
                title={`Inheriting LaunchDarkly Module: TierII flag (${isTierIIEnabled})`}
              >
                {isTierIIEnabled ? <FlagIcon /> : <OutlinedFlagIcon />}
              </Tooltip>
            );
          }
          return null;
        },
        minWidth: 100,
      },
      {
        field: "customerFacilityId",
        headerName: "Customer Facility Id",
      },
      {
        field: "streetAddress1",
        headerName: "Address",
        flex: 0.5,
        minWidth: 200,
        valueGetter: (params) => {
          const { streetAddress1, streetAddress2, city, state, zip, country } =
            params.row;

          return compact([
            streetAddress1,
            streetAddress2,
            city,
            state,
            zip,
            country,
          ]).join(", ");
        },
      },
      {
        field: "credentialTypes",
        headerName: "Credential Types",
        sortable: false,
        align: "right",
        filterKeyType: "enum",
        enumValues: Object.values(CredentialKind),
        renderCell: ({ row }) => (
          <Stack direction="column" spacing={0.5} alignItems="flex-end">
            {row.facilityCredentials?.map((fc, index) => (
              <Box key={index} display="flex" alignItems="center">
                {credentialKindToLabel(fc.credential.kind)}
              </Box>
            ))}
          </Stack>
        ),
      },
      {
        // Displays the list of credential statuses as icons and their last
        // verification check date
        field: "credentialStatus",
        headerName: "Verification Status",
        sortable: false,
        renderCell: renderVerificationStatusCell,
      },
      {
        field: "hasStateId",
        headerName: "State ID",
        headerAlign: "center",
        sortable: false,
        align: "center",
        renderCell({ row }) {
          const isIdRequired = stateRequiresStateId(row.state);
          if (!row.state || !isIdRequired) {
            return null;
          }

          const hasAllStateIds = hasStateId(
            row.state,
            row.facilityAlternateIds ?? []
          );

          if (hasAllStateIds) {
            return <Check />;
          }

          return (
            <Tooltip title="Facility is missing necessary state ID(s)">
              <WarningAmberRounded />
            </Tooltip>
          );
        },
      },
      {
        field: "hasECHO",
        headerName: "ECHO",
        headerAlign: "center",
        align: "center",
        sortable: false,
        valueGetter: ({ row }) => {
          const id = findAlternateId(row.facilityAlternateIds ?? [], [
            FacilityAlternateIdKnownKind.FRS,
          ]);
          return !!id;
        },
        renderCell({ value }) {
          if (value) {
            return <Check />;
          }
          return null;
        },
        filterKeyType: "boolean",
      },
      {
        field: "lastExtraction",
        headerName: "Last Extraction",
        sortable: false,
        valueGetter: ({ row }) => row.jobs?.[0]?.updatedAt,
        valueFormatter: ({ value }) =>
          value ? prettyPrintDateTime(value) : undefined,
        filterKeyType: "time",
        renderCell: ({ formattedValue, row: { id: facilityId } }) => {
          return (
            <Link
              to={`?omnisearch=facilityId%3A${facilityId}&tab=extractions`}
              target="_blank"
              className="MuiDataGrid-cellContent"
            >
              {formattedValue}
            </Link>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        valueFormatter: (props) => prettyPrintDateTime(props.value),
        filterKeyType: "time",
      },
      {
        field: "updatedAt",
        headerName: "Updated",
        valueFormatter: (props) => prettyPrintDateTime(props.value),
        filterKeyType: "time",
      },
      {
        field: "collectionMode",
        headerName: "Collection Mode",
        type: "string",
        valueGetter(params) {
          switch (params.row.collectionMode) {
            case CollectionMode.Aggregate:
              return "Aggregate";
            case CollectionMode.Measurement:
              return "Measurement";
            case CollectionMode.MeasurementDefaulted:
              return "Measurement (Default)";
            case CollectionMode.AggregateDefaulted:
              return "Aggregate (Default)";
            default:
              return "Unknown";
          }
        },
      },
      {
        field: "issues",
        headerName: "Issues",
        flex: 0.15,
        align: "center",
        headerAlign: "center",
        renderCell: ({ row }) => {
          return (
            <Box
              sx={{
                py: theme.spacing(1),
                display: "flex",
                alignItems: "start",
                height: "100%",
              }}
            >
              <IssueCount issueCount={row.issueCount} />
            </Box>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        maxWidth: 50,
        getActions: (params) => [
          <Tooltip title="Delete facility" key={1}>
            <GridActionsCellItem
              onClick={() => setDeleteFacilityId(params.row.id)}
              label="Delete facility"
              icon={<Delete />}
            />
          </Tooltip>,
        ],
      },
    ],
    [isTierIIEnabled, isWasteEnabled, theme]
  );

  const [deleteFacilityId, setDeleteFacilityId] = useState<string | null>();

  const [deleteFacility, { loading }] = useMutation(DELETE_FACILITY_MUTATION, {
    variables: { id: deleteFacilityId ?? "" },
    onCompleted: () => setDeleteFacilityId(null),
    update(cache, { data }) {
      if (!data?.deleteFacility) return;
      const id = cache.identify(data.deleteFacility);

      cache.modify({
        fields: {
          facilities(existingFacilities) {
            if (existingFacilities?.items) {
              // Filter out the deleted facility based on its ID
              const newItems = existingFacilities.items.filter(
                (facilityRef: Reference) => facilityRef.__ref !== id
              );

              // Return the updated facilities with the deleted facility removed
              return { ...existingFacilities, items: newItems };
            }

            // Return the existing facilities unchanged if items are not found
            return existingFacilities;
          },
        },
      });
    },
  });

  const [selectedRows, setSelectedRows] = useState<Row[]>([]);

  const commandButtons = [
    <FacilitiesBulkActionsMenu
      key="bulk-action-menu"
      selectedRows={selectedRows}
    />,
    <FacilitiesExportButton key="export" tenantId={tenantId} />,
    <Importer
      key="import"
      templateType={OneSchemaTemplateType.Facility}
      refetchQueries={["ImplementationFacilities"]}
    />,
  ];

  return (
    <>
      <OmnisearchDataGrid
        withPadding={false}
        columns={columns}
        dataQuery={FACILITIES_WITH_JOBS_QUERY}
        defaultSearch={`tenantId:${tenantId}`}
        commandButtons={commandButtons}
        getRowHeight={(row) => {
          const itemCount = row.model.facilityCredentials?.length || 1;
          return itemCount * 38 - (itemCount - 1) * 12;
        }}
        noDataMessage="Organization has no facilities."
        getItems={(data) => data.facilities.items}
        getCount={(data) => data.facilities.count}
        onSelectedRowsChanged={setSelectedRows}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name"],
            right: ["actions"],
          },
        }}
        excludeFilterColumns={[
          "hasStateId",
          "lastExtraction",
          "credentialStatus",
          "issues",
        ]}
        additionalFilterColumns={[
          {
            header: "Has Credential",
            key: "hasCredential",
            filterKeyType: "boolean",
          },
        ]}
      />
      <ConfirmDialog
        open={!!deleteFacilityId}
        msg={"Are you sure you want to delete this facility?"}
        title="Delete this facility?"
        loading={loading}
        onConfirm={deleteFacility}
        onCancel={() => setDeleteFacilityId(null)}
      />
    </>
  );
}

/**
 * Renders the verification status for a facility's credentials along with the
 * last verification check date. The credentials will be rendered in the order
 * they are in in the facilityCredentials array.
 */
function renderVerificationStatusCell({ row }: { row: Row }) {
  return (
    <Stack direction="column" spacing={0.5}>
      {row.facilityCredentials?.map((fc, index) => (
        <Box key={index} display="flex" alignItems="center">
          <Tooltip
            title={prettyPrintEnumValue(fc.credential.verificationStatus)}
          >
            {getCredentialStatusIcon(fc.credential.verificationStatus)}
          </Tooltip>
          {fc.credential.lastVerificationCheckAt && (
            <Tooltip
              title={prettyPrintDateTime(fc.credential.lastVerificationCheckAt)}
            >
              <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                {getUtcDisplayDate(fc.credential.lastVerificationCheckAt)}
              </Typography>
            </Tooltip>
          )}
        </Box>
      ))}
    </Stack>
  );
}

function getCredentialStatusIcon(
  verificationStatus: CredentialVerificationStatus
) {
  switch (verificationStatus) {
    case CredentialVerificationStatus.Good:
      return <Check color="success" fontSize="small" />;
    case CredentialVerificationStatus.CurrentlyVerifying:
    case CredentialVerificationStatus.Verifying:
      return <HourglassTop color="warning" fontSize="small" />;
    case CredentialVerificationStatus.NotAttempted:
      return <QuestionMark color="warning" fontSize="small" />;
    case CredentialVerificationStatus.Locked:
      return <Lock color="error" fontSize="small" />;
    default:
      return <Error color="error" fontSize="small" />;
  }
}
