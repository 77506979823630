import { useMutation, useQuery } from "@apollo/client";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { EditPerson } from "components/Contacts/EditPerson";
import { Dialog } from "components/Dialog";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { gql } from "generated-graphql";
import {
  ContactReportRole,
  GetPersonForEditQuery,
  PersonInput,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacilityContact";
import { useTenant } from "hooks/useTenant";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useWatch } from "react-hook-form";
import { usePersonFacilityContactsInputValidation } from "routes/Customer/Chemicals/Report/validationHooks/usePersonFacilityContactsInputValidation";
import { hasCriticalIssues } from "util/forms";
import AssociatedFacilities from "./AssociatedFacilities";
import { ConfirmPersonChangesModal } from "./ConfirmPersonChangesModal";

const GET_PERSON = gql(`
  query GetPersonForEdit($id: ID!) {
    person(id: $id) {
      id
      tenantId
      first
      last
      phones {
        number
        type
      }
      email
      title
      company
    
      streetAddress1
      streetAddress2
      city
      country
      state
      zip

      tankOwnerType
      
      facilityContacts {
        id
        reportingRoles
        facility {
          id
          name
          streetAddress1
          streetAddress2
          city
          state
          zip
          country
        }
      }
      user {
        id
        email
      }
      issues {
        ...issue
      }
    }
  }
`);

const UPSERT_PERSON = gql(`
  mutation UpsertPerson($input: PersonFacilityContactsInput!) {
    upsertPerson(input: $input) {
      id
    }
  }
`);

export type EditContactFormData = {
  person?: GetPersonForEditQuery["person"];
};

export const EditContactDialog = ({
  open,
  onClose,
  onSave,
  personId,
  refetchQueries,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (person: PersonInput | undefined) => void;
  personId?: string;
  refetchQueries?: string[];
}) => {
  const alerts = useAlerts();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const theme = useTheme();
  const { tenantId } = useTenant();

  const { data: queryData, loading } = useQuery(GET_PERSON, {
    variables: { id: personId ?? "" },
    skip: !personId,
    fetchPolicy: "cache-and-network",
  });
  const person = queryData?.person ?? undefined;

  const defaultValues: EditContactFormData | undefined = useMemo(
    () => ({
      id: person?.id ?? "",
      person: {
        ...person,
        tenantId: person?.tenantId ?? tenantId ?? "",
        id: person?.id ?? "",
      },
      facilityContacts: person?.facilityContacts,
    }),
    [person, tenantId]
  );

  const [upsertPerson, { loading: savingPerson }] = useMutation(UPSERT_PERSON, {
    refetchQueries: [...(refetchQueries ?? []), GET_PERSON],
  });

  const form = useValidatingForm<EditContactFormData>(
    defaultValues,
    person?.issues ?? [],
    usePersonFacilityContactsInputValidation()
  );

  const {
    handleSubmit,
    issues,
    control,
    formState: { isDirty },
  } = form;

  // Used to update facilityContact records
  const { remove, replace } = useFieldArray({
    control,
    name: "person.facilityContacts",
  });

  const editingMode = useMemo(() => {
    return personId ? "edit" : "add";
  }, [personId]);

  const upsert = useCallback(
    async (data: EditContactFormData) => {
      if (!data.person) {
        return;
      }
      if (!tenantId) {
        throw new Error("Tenant ID is required");
      }
      const input = transform(data);
      const result = await upsertPerson({ variables: { input } });
      if (editingMode === "add") {
        // call onSave including the new id
        const id = result.data?.upsertPerson?.id;
        onSave({ ...data.person, id });
      } else {
        onSave(data.person);
      }
      return result.data?.upsertPerson?.id;
    },
    [upsertPerson, onSave, tenantId, editingMode]
  );

  const onSubmit = useCallback(
    async (data: EditContactFormData) => {
      const updatePerson = data.person;
      if (!updatePerson) {
        return;
      }
      if (
        updatePerson?.id?.length &&
        updatePerson?.facilityContacts?.length &&
        isDirty
      ) {
        setShowConfirmModal(true);
      } else {
        try {
          await upsert(data);
          onClose();
          alerts.success("Contact added successfully");
        } catch (error) {
          alerts.error("Error adding contact", error);
        }
      }
    },
    [isDirty, upsert, alerts, onClose]
  );

  const watchedFacilityContacts = useWatch({
    name: "person.facilityContacts",
    control,
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="xl"
      PaperProps={{ sx: { minHeight: "95vh" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {editingMode !== "add" ? `Edit Contact` : `Add Contact`}
        </DialogTitle>
        <DialogContent>
          {editingMode === "edit" && person?.user ? (
            <Typography variant="body1" sx={{ mb: theme.spacing(3) }}>
              You cannot update this contact's email or name because it is
              associated with a specific person in your organization. If you'd
              like a contact with a different email please create a new contact.
              Reach out to your Encamp Customer Service Manager if you have
              additional questions.
            </Typography>
          ) : null}

          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={theme.spacing(3)}
            sx={{ mt: theme.spacing(1) }}
          >
            <Grid container spacing={theme.spacing(3)} width="50%">
              <Grid item xs={12}>
                <FormProvider {...form}>
                  <EditPerson
                    mode={editingMode}
                    isUser={!!person?.user}
                    loading={loading}
                    issues={issues}
                    showTankOwnerType={
                      form
                        .getValues()
                        .person?.facilityContacts?.some((fc) =>
                          fc.reportingRoles.some(
                            (rr) => rr === ContactReportRole.EpcraCaTankOwner
                          )
                        ) ?? false
                    }
                  />
                </FormProvider>
              </Grid>
            </Grid>
            <Stack width="50%">
              <AssociatedFacilities
                facilityContacts={watchedFacilityContacts ?? []}
                onUpdate={(updates) => replace(updates)}
                onRemove={(index) => remove(index)}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ pr: theme.spacing(2) }}>
          <FloatingSaveBar
            onCancel={() => {
              onClose();
            }}
            issues={issues}
            saving={savingPerson}
            saveDisabled={hasCriticalIssues(issues)}
            sx={{ m: 0, px: 0, py: 1 }}
          />
        </DialogActions>
      </form>
      {showConfirmModal && (
        <ConfirmPersonChangesModal
          personFullName={`${person?.first} ${person?.last}`}
          facilities={watchedFacilityContacts?.map((fc) => fc.facility) ?? []}
          saving={savingPerson}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={handleSubmit(async (data) => {
            try {
              await upsert(data);
              setShowConfirmModal(false);
              alerts.success("Contact updated successfully");
            } catch (error) {
              alerts.error("Error updating contact", error);
            }
          })}
        />
      )}
    </Dialog>
  );
};
