import { useLazyQuery, useMutation } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import Loop from "@mui/icons-material/Loop";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { gql } from "generated-graphql";
import {
  JobStatus,
  JobTask,
  StaffReportJobsQuery,
  StaffReportType,
} from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { useCallback, useMemo, useState } from "react";
import { prettyPrintDateTime } from "util/dates";

gql(`
  fragment StaffReportJobFragment on Job {
    id
    status
    updatedAt
    totalIssues
    staffReportKey
    parameters
    tenant {
      id
      name
    }
  }
`);

const STAFF_REPORTS = gql(`
  query StaffReportJobs($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    jobs(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      items {
        ...StaffReportJobFragment
      }
    }
  }
`);

const GET_DOCUMENT_DOWNLOAD_LINK_QUERY = gql(`
  query GetStaffReportDownloadLink($id: ID!) {
    job(id: $id) {
      staffReportDownloadLink
    }
  }
`);

const GENERATE_STAFF_REPORT = gql(`
  mutation GenerateStaffReport($reportType: StaffReportType!, $reportingYear: Int!, $tenantId: String) {
    generateStaffReport(reportType: $reportType, reportingYear: $reportingYear, tenantId: $tenantId) {
      ...StaffReportJobFragment
    }
  }
`);

type Row = StaffReportJobsQuery["jobs"]["items"][number];

export const StaffReports = () => {
  const { tenantId, tenant } = useTenant();
  const alerts = useAlerts();
  const theme = useTheme();
  const [getDownloadLink] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_LINK_QUERY, {
    onCompleted: ({ job: { staffReportDownloadLink } }) => {
      if (staffReportDownloadLink) {
        window.open(staffReportDownloadLink, "_blank");
      }
    },
    onError(error) {
      alerts.error("An error occurred while downloading the report", error);
    },
  });
  const [reportHasBeenGenerated, setReportHasBeenGenerated] = useState(false);
  const [generateReport, { loading }] = useMutation(GENERATE_STAFF_REPORT, {
    refetchQueries: [STAFF_REPORTS],
    onCompleted: () => {
      setGenerateReportOpen(false);
      setReportHasBeenGenerated(true);
    },
    onError: (error) => {
      alerts.error("Error generating report", error);
      setGenerateReportOpen(false);
    },
  });
  const [generateReportOpen, setGenerateReportOpen] = useState(false);
  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        field: "organization",
        headerName: "Organization",
        flex: 1,
        valueGetter: ({ row }) => row.tenant?.name ?? "All tenants",
      },
      {
        field: "reportType",
        headerName: "Report Type",
        flex: 1,
        valueGetter: ({ row }) => row.parameters["reportType"],
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        filterKeyType: "enum",
        enumValues: Object.values(JobStatus),
      },
      {
        field: "updatedAt",
        headerName: "Generated On",
        valueFormatter: ({ value }) => prettyPrintDateTime(value),
        flex: 1,
        filterKeyType: "time",
      },
      {
        field: "totalIssues",
        headerName: "Issues",
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => <IssueCount issueCount={value} />,
        filterKeyType: "number",
      },
      {
        field: "actions",
        type: "actions",
        getActions({ row }) {
          if (row.status === JobStatus.Succeeded)
            return [
              <GridActionsCellItem
                key="download"
                label="Download"
                icon={<Download />}
                onClick={() =>
                  getDownloadLink({
                    variables: { id: row.id },
                  })
                }
              />,
            ];

          if (
            row.status === JobStatus.Queued ||
            row.status === JobStatus.Running
          )
            return [
              <GridActionsCellItem
                key="queued"
                label="Queued"
                disabled
                icon={<Loop />}
              />,
            ];

          return [];
        },
      },
    ],
    [getDownloadLink]
  );

  const shouldPoll = useCallback((data: StaffReportJobsQuery) => {
    const polling = data.jobs.items.some((j) =>
      [
        JobStatus.Running,
        JobStatus.Queued,
        JobStatus.ProvisioningEcsTask,
      ].includes(j.status as JobStatus)
    );
    return polling ? 2000 : undefined;
  }, []);

  return (
    <>
      <OmnisearchDataGrid
        withPadding={false}
        columns={columns}
        dataQuery={STAFF_REPORTS}
        defaultSearch={`task:${JobTask.StaffReport} tenantId:${tenantId} or task:${JobTask.StaffReport} tenantId:null`}
        getItems={(data) => data.jobs.items}
        getCount={(data) => data.jobs.count}
        shouldPoll={shouldPoll}
        initialSortModel={[{ sort: "desc", field: "updatedAt" }]}
        excludeFilterColumns={["reportType", "organization"]}
        commandButtons={[
          <Button
            key="generate"
            variant="contained"
            disabled={reportHasBeenGenerated}
            onClick={() => setGenerateReportOpen(true)}
          >
            Generate Report
          </Button>,
        ]}
      />
      <Dialog
        open={generateReportOpen}
        onClose={() => setGenerateReportOpen(false)}
      >
        <DialogTitle>Generate Staff Report</DialogTitle>
        <DialogContent>
          This will generate a validation report. Are you sure you want to
          generate a validation report for {tenant?.name}?
          {/* We should run all tenant validation reports on ECS as a lambda
              is likely not capable of validating all data for a reporting year */}
          {/* <Box paddingTop={theme.spacing(2)} />
          <Typography variant="subtitle2">
            You can also generate a report for all tenants by clicking{" "}
            <b
              style={{ cursor: "pointer" }}
              onClick={() =>
                generateReport({
                  variables: {
                    reportType: StaffReportType.TierIiValidation,
                    reportingYear: currentTierIIReportingYear,
                  },
                })
              }
            >
              <span style={{ textDecoration: "underline" }}>here</span>
            </b>
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGenerateReportOpen(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() =>
              generateReport({
                variables: {
                  reportType: StaffReportType.TierIiValidation,
                  reportingYear: currentTierIIReportingYear,
                  tenantId,
                },
              })
            }
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
