import { LocalizationProvider } from "@mui/x-date-pickers";
import { LocalizationProvider as ProLocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { AdapterLuxon as ProAdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";

export function LocalizationProviderWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ProLocalizationProvider dateAdapter={ProAdapterLuxon}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        {children}
      </LocalizationProvider>
    </ProLocalizationProvider>
  );
}
