import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { gql } from "generated-graphql";
import pluralize from "pluralize";
import { useCallback, useMemo } from "react";
import { useBulkActionContext } from "../Facilities/FacilitiesBulkActionMenu";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "components/DataGrid";
import { FeatureFlagOverride } from "encamp-shared/src/generated-graphql/oak-resolver-types";

const SET_WASTE_FLAG = gql(/* GraphQL */ `
  mutation SetWasteFlagForFacilities(
    $facilityIds: [ID!]!
    $featureFlagOverride: FeatureFlagOverride!
  ) {
    setWasteFlagForFacilities(
      facilityIds: $facilityIds
      featureFlagOverride: $featureFlagOverride
    ) {
      succeeded
    }
  }
`);

export function BulkSetWasteFlagModal() {
  const {
    selectedRows: selectedFacilities,
    dialogState,
    onClose: handleClose,
  } = useBulkActionContext();

  const alerts = useAlerts();
  const theme = useTheme();

  const closeDialog = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const [setWasteFlag, { loading }] = useMutation(SET_WASTE_FLAG);
  const featureFlagOverride = useMemo(() => {
    // This narrows the type so the type checker doesn't complain
    if (dialogState?.action === "setWasteFlag") {
      return dialogState.featureFlagOverride;
    }
    return FeatureFlagOverride.InheritLdFlagForTenant;
  }, [dialogState]);

  const onSubmit = useCallback(async () => {
    try {
      const mutationResult = await setWasteFlag({
        variables: {
          facilityIds: selectedFacilities.map((f) => f.id),
          featureFlagOverride,
        },
        refetchQueries: ["ImplementationFacilities"],
        awaitRefetchQueries: true,
      });

      const results = mutationResult.data?.setWasteFlagForFacilities;
      const succeeded = results?.succeeded;

      if (succeeded) {
        alerts.success(
          `Successfully set waste flag for ${selectedFacilities.length} facility.`
        );
        return;
      }
    } catch (err) {
      alerts.error(`Error saving facilities:`, err);
    } finally {
      handleClose();
    }
  }, [
    setWasteFlag,
    selectedFacilities,
    featureFlagOverride,
    alerts,
    handleClose,
  ]);

  return (
    <Dialog open={dialogState?.action === "setWasteFlag"} onClose={closeDialog}>
      <Box>
        <DialogTitle>
          Turn{" "}
          {featureFlagOverride === FeatureFlagOverride.Disabled ? "off" : "on"}{" "}
          Waste
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle1"
            mb={2}
            sx={{ color: theme.palette.text.secondary }}
          >
            You are going to turn{" "}
            {featureFlagOverride === FeatureFlagOverride.Disabled
              ? "off"
              : "on"}{" "}
            the Waste module for {selectedFacilities.length}{" "}
            {pluralize("facility", selectedFacilities.length)}.
          </Typography>
          <DataGrid
            rows={selectedFacilities}
            disableRowSelectionOnClick
            columns={[{ field: "name", headerName: "Facility Name", flex: 1 }]}
          />
          <Typography mt={2} fontWeight={theme.typography.fontWeightMedium}>
            Are you sure that you'd like to make this change?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} disabled={loading}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={onSubmit}
          >
            Yes, I'm sure
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
