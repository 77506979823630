import { useLazyQuery } from "@apollo/client";
import { HmbpUndergroundStorageTankSection } from "encamp-shared/src/hmbp";
import { gql } from "generated-graphql";
import { UndergroundStorageTankInput } from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformUndergroundStorageTank";
import { useCallback } from "react";
import { isHmbpIssue } from "../useHmbp";

const VALIDATE_UNDERGROUND_STORAGE_TANK_INPUT = gql(`
  query ValidateUndergroundStorageTankInput($input: UndergroundStorageTankInput!, $pick: [String!]) {
    undergroundStorageTankInputValidator(input: $input, pick: $pick) {
      ...issue
    }
  }
`);

export function useUndergroundStorageTankInputValidation(
  section: HmbpUndergroundStorageTankSection
) {
  const [query] = useLazyQuery(VALIDATE_UNDERGROUND_STORAGE_TANK_INPUT, {
    fetchPolicy: "no-cache",
  });

  return useCallback(
    async (input: UndergroundStorageTankInput) => {
      const { data } = await query({
        variables: { input: transform({ ...input }) },
      });
      return (
        data?.undergroundStorageTankInputValidator.filter((issue) =>
          isHmbpIssue(issue, { section })
        ) ?? []
      );
    },
    [section, query]
  );
}
