import { Button, Stack, Typography, useTheme } from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { useFormContext } from "react-hook-form";

type ExemptProps = {
  handleBack: () => void;
  loading?: boolean;
};

export function Exempt({ handleBack, loading }: ExemptProps) {
  const theme = useTheme();
  const { control, watch } = useFormContext();

  const explanation: string | null = watch("exemptExplanation");

  return (
    <>
      <Typography variant="body1" marginBottom={theme.spacing(2)}>
        Exempt
      </Typography>

      <Typography variant="body2" marginBottom={theme.spacing(2)}>
        Select this option if you feel you are exempt from this document upload
        requirement. Provide a short explanation of why you feel you are exempt
        in the space provided below. If the regulatory agency does not approve
        of the document exemption, the submittal will be rejected.
      </Typography>

      <FormTextField
        control={control}
        name="exemptExplanation"
        label="Explanation of why your facility is exempt"
        textFieldProps={{
          required: true,
        }}
      />

      <Stack
        direction="row"
        gap={theme.spacing(2)}
        justifyContent="flex-end"
        sx={{ marginTop: theme.spacing(2) }}
      >
        <Button onClick={handleBack} variant="outlined">
          Back
        </Button>
        <SaveButton
          loading={loading ?? false}
          disabled={explanation === null}
        />
      </Stack>
    </>
  );
}
