import { useMutation } from "@apollo/client";
import ApprovalOutlined from "@mui/icons-material/ApprovalOutlined";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import {
  VerifyAndSubmitFormDialog,
  VerifyAndSubmitFormState,
} from "components/VerifyAndSubmitFormDialog";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { gql } from "generated-graphql";
import { TierIiReportEncampStatus } from "generated-graphql/graphql";
import { useCallback, useMemo } from "react";
import { useBulkActionContext } from ".";

export function VerifyAndSubmitMenuItem() {
  const { setDialogState } = useBulkActionContext();

  return (
    <MenuItem onClick={() => setDialogState("verify&submit")}>
      <ListItemIcon>
        <ApprovalOutlined />
      </ListItemIcon>
      <ListItemText>Verify & Submit</ListItemText>
    </MenuItem>
  );
}

export const BULK_VERIFY_MUTATION = gql(`
  mutation BulkVerify($reportIds: [ID!]!, $verifier: TierIIReportVerifierInput!) {
    bulkVerifyAndSubmitReports(reportIds: $reportIds, verifier: $verifier) {
      succeeded
    }
  }
`);

export function VerifyAndSubmitDialog() {
  const alerts = useAlerts();
  const { dialogState, onClose, selectedRows } = useBulkActionContext();

  const verifiableRows = useMemo(
    () =>
      selectedRows.filter(
        (row) =>
          row.encampStatus === TierIiReportEncampStatus.AwaitingVerification
      ),
    [selectedRows]
  );

  const skippedDueToVerificationCount =
    selectedRows.length - verifiableRows.length;

  const reportsWithValidationErrorsCount = useMemo(
    () => selectedRows.filter((row) => row.issueCount > 0).length,
    [selectedRows]
  );
  const verifiableRowsWithoutOpenReviews = useMemo(
    () => verifiableRows.filter((row) => !row.hasIncompleteReviews),
    [verifiableRows]
  );
  const verifiableRowsWithoutOpenReviewsCount =
    verifiableRowsWithoutOpenReviews.length;

  const skippedDueToOpenReviewsCount =
    verifiableRows.length - verifiableRowsWithoutOpenReviews.length;

  const [mutate, { loading }] = useMutation(BULK_VERIFY_MUTATION, {
    refetchQueries: ["TierIIReportActivities"],
  });

  const submit = useCallback(
    async (
      formState: VerifyAndSubmitFormState,
      overrideOpenReviews: boolean
    ) => {
      try {
        await mutate({
          variables: {
            reportIds: (overrideOpenReviews
              ? verifiableRows
              : verifiableRowsWithoutOpenReviews
            )
              .map((row) => row.tierIIReportId)
              .filter(hasValue),
            verifier: {
              personId: formState.personId ?? null,
              verifiedAt: formState.date,
              verifierFullName: `${formState.firstName} ${formState.lastName}`,
              verifierTitle: formState.title,
            },
          },
        });
        alerts.info("Starting bulk verification.");
      } catch (err) {
        alerts.error("An error occurred during bulk verification", err);
      }
      onClose();
    },
    [alerts, mutate, onClose, verifiableRows, verifiableRowsWithoutOpenReviews]
  );

  return (
    <VerifyAndSubmitFormDialog
      withConfirmation
      open={dialogState === "verify&submit"}
      onClose={onClose}
      loading={loading}
      onSubmit={submit}
      skippedDueToOpenReviewsCount={skippedDueToOpenReviewsCount}
      skippedDueToVerificationCount={skippedDueToVerificationCount}
      verifiableRowsWithoutOpenReviewsCount={
        verifiableRowsWithoutOpenReviewsCount
      }
      reportsWithValidationErrorsCount={reportsWithValidationErrorsCount}
    />
  );
}
