import FileDownload from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from "@mui/material";
import { useToken } from "providers/token";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAlerts } from "./Alerts/AlertProvider";
import { LongPollingJobWrapper } from "./LongPollingJobWrapper";
import { GetJobQuery } from "generated-graphql/graphql";
import {
  JobStatus,
  JobTask,
} from "encamp-shared/src/generated-graphql/oak-resolver-types";
import { useCheckForFile } from "hooks/useCheckForFile";
import { kebabCase } from "lodash";

export type ExportDataButtonProps = {
  tenantId?: string;
  fileName?: string;
  facilityId?: string;
  reportingYear?: number;
  exportType: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  dialog?: boolean;
  dialogTitle?: string;
  generateMessage?: string;
  timeoutInSeconds?: number;
};

export const ExportDataButton: React.FC<ExportDataButtonProps> = ({
  tenantId,
  facilityId,
  reportingYear,
  exportType,
  fileName,
  dialog = false,
  dialogTitle = "Generating Export",
  generateMessage = "Your file is being generated. This may take a few minutes...",
  timeoutInSeconds = undefined,
  ...props
}) => {
  const { getIdTokenRefreshIfExpired } = useToken();
  const alerts = useAlerts();
  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [signedUrl, setSignedUrl] = useState<string | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  timeoutInSeconds = timeoutInSeconds ?? (dialog ? 900 : 120);

  const fullFileName = useMemo(() => {
    return generateSpreadsheetFilename(fileName ?? exportType);
  }, [fileName, exportType]);

  const s3Key = useMemo(() => {
    return `spreadsheet-exports/${
      tenantId ?? kebabCase(exportType)
    }/${fullFileName}`;
  }, [tenantId, fullFileName]);

  const { fetchFile } = useCheckForFile({ key: s3Key });

  const endpoint = useMemo(() => {
    return `/download/${exportType}?filename=${fullFileName}${
      tenantId ? `&tenantId=${tenantId}` : ""
    }${facilityId ? `&facilityId=${facilityId}` : ""}${
      reportingYear ? `&reportingYear=${reportingYear}` : ""
    }`;
  }, [tenantId, facilityId, reportingYear, exportType, fullFileName]);

  const handleDownload = useCallback((url: string) => {
    if (!url) return;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, []);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleDownloadClick = useCallback(() => {
    if (signedUrl) {
      handleDownload(signedUrl);
      handleClose();
    }
  }, [signedUrl, handleDownload, handleClose]);

  const handleExportClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (signedUrl) {
        handleDownload(signedUrl);
        return;
      }

      setIsLoading(true);
      if (dialog) {
        setIsDialogOpen(true);
      }

      const apiUrl =
        import.meta.env.MODE === "development"
          ? "http://localhost:4000"
          : import.meta.env.VITE_API_URL;

      const idToken = await getIdTokenRefreshIfExpired();
      const authorization = `Bearer ${idToken || ""}`;

      try {
        const response = await fetch(`${apiUrl}${endpoint}`, {
          headers: {
            authorization,
          },
        });

        if (response.status >= 400) {
          alerts.error("Error generating export file");
        } else {
          const { jobId } = await response.json();
          setJobId(jobId);
        }
      } catch (err) {
        console.error(err);
        alerts.error("Error generating export file");
        setIsLoading(false);
      }
    },
    [
      getIdTokenRefreshIfExpired,
      alerts,
      endpoint,
      signedUrl,
      dialog,
      handleDownload,
    ]
  );

  const pollingCompleted = useCallback(
    async (completedJob: GetJobQuery["job"]) => {
      setIsLoading(false);
      if (completedJob?.status === JobStatus.Failed) {
        const actionName =
          completedJob.task === JobTask.GeneratePdf ? "PDF" : "export file";
        alerts.error(`Failed to generate ${actionName}`);
        return;
      }

      const signedUrl = await fetchFile();

      if (signedUrl) {
        setSignedUrl(signedUrl);
      } else {
        alerts.error("Failed to generate export file");
      }
    },
    [fetchFile, alerts]
  );

  useEffect(() => {
    if (signedUrl) {
      handleDownload(signedUrl);
    }
  }, [signedUrl, handleDownload]);

  useEffect(() => {
    setSignedUrl(undefined);
  }, [s3Key]);

  return (
    <>
      <LongPollingJobWrapper
        jobId={jobId}
        pollingCompleted={pollingCompleted}
        timeoutInSeconds={timeoutInSeconds}
      >
        <LoadingButton
          variant="outlined"
          size="small"
          startIcon={<FileDownload />}
          loading={isLoading}
          onClick={handleExportClick}
          disabled={props.disabled}
        >
          Export
        </LoadingButton>
      </LongPollingJobWrapper>

      {dialog && (
        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent sx={{ minHeight: 70 }}>
            {!signedUrl ? (
              <Typography>{generateMessage}</Typography>
            ) : (
              <>
                <Typography>
                  Your file has been generated and should download
                  automatically.
                </Typography>
                <Typography>
                  If the download didn't start, you can{" "}
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={handleDownloadClick}
                    className="text-blue-600 underline"
                  >
                    click here
                  </Link>{" "}
                  to download it manually.
                </Typography>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              onClick={handleDownloadClick}
              variant="contained"
              data-testid="confirm"
            >
              Download
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export function generateSpreadsheetFilename(prefix: string): string {
  return `${prefix.replace(/[&]+/g, "")}-${new Date().toISOString()}.xlsx`;
}
