import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Box,
} from "@mui/material";
import { DynamicField, Issue } from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useState, PropsWithChildren } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { ConfirmDialog } from "./ConfirmDialog";
import { Dialog } from "./Dialog";
import { IssueListButton } from "./Forms/IssueListButton";
import { StateFieldsForm } from "./Forms/StateFieldsForm";
import { SaveButton } from "./SaveButton";
import { useDeepCompareMemoize } from "use-deep-compare-effect";

export const DynamicFormDialog = (props: DynamicFormDialogContentProps) => {
  // avoid form being initialized too early if dialog is not open yet
  if (props.open) {
    return <DynamicFormDialogContent {...props} />;
  }
};

type DynamicFormDialogContentProps = PropsWithChildren<
  {
    title: string;

    persistedIssues: Issue[];
    fields: DynamicField[];
    onSubmit: SubmitHandler<DynamicField[]>;
    onClose: () => void;
    validator: (input: DynamicField[]) => Promise<Issue[]>;
  } & Omit<DialogProps, "onSubmit" | "onClose">
>;

type DynamicFormDialogContentInput = {
  fields: DynamicField[];
};

const DynamicFormDialogContent = ({
  title,
  persistedIssues,
  fields,
  onSubmit,
  onClose,
  validator,
  children,
  ...dialogProps
}: DynamicFormDialogContentProps) => {
  const fieldNamePrefix: keyof DynamicFormDialogContentInput = "fields";
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const form = useValidatingForm<DynamicFormDialogContentInput>(
    useDeepCompareMemoize({ fields }),
    persistedIssues,
    ({ fields }) => validator(fields),
    [fieldNamePrefix]
  );
  const {
    formState: { isSubmitting, isDirty, isSubmitSuccessful },
    handleSubmit,
    issues,
  } = form;
  const hasPendingChanges = isDirty && !isSubmitSuccessful;

  const handleFormSubmit = useCallback(
    () => handleSubmit(({ fields }) => onSubmit(fields))(),
    [handleSubmit, onSubmit]
  );

  const handleDialogClose = useCallback(() => {
    if (hasPendingChanges) {
      setShowConfirmClose(true);
    } else {
      onClose();
    }
  }, [hasPendingChanges, onClose]);

  return (
    <>
      <Dialog {...dialogProps} open={true} onClose={handleDialogClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children && <Box sx={{ mb: 3 }}>{children}</Box>}
          <FormProvider {...form}>
            <StateFieldsForm
              fields={fields}
              namePrefix={fieldNamePrefix}
              applyDynamicLayout={true}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <IssueListButton issues={issues} />
          <Button variant="outlined" onClick={handleDialogClose}>
            Cancel
          </Button>
          <SaveButton loading={isSubmitting} onClick={handleFormSubmit} />
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={showConfirmClose}
        onConfirm={onClose}
        onClose={() => setShowConfirmClose(false)}
        title="Are you sure you want to leave?"
        msg="You have unsaved changes."
      />
    </>
  );
};
