import { useMutation } from "@apollo/client";
import Restore from "@mui/icons-material/Restore";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  ResetReportsMutation,
  ResetReportsMutationVariables,
  TierIiReportEncampStatus,
  TierIiReportOrgStatus,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { useCallback } from "react";
import { useAlerts } from "../../../../components/Alerts/AlertProvider";
import { gql } from "generated-graphql";

const RESET_REPORTS = gql(`
  mutation ResetReports($input: BulkResetReportInput!) {
    bulkResetTierIIReports(input: $input) {
        succeeded
    }
  }
`);

type Props = {
  disabled?: boolean;
  onSubmit: () => void;
  reportIds: string[];
  encampStatus: TierIiReportEncampStatus;
  organizationStatus: TierIiReportOrgStatus;
  safetyOverride: boolean;
  untouchedSteps: TierIiReportStep[];
};

export const SubmitResetReportsButton = (props: Props) => {
  const {
    onSubmit,
    reportIds,
    encampStatus,
    organizationStatus,
    safetyOverride,
    untouchedSteps,
  } = props;
  const alerts = useAlerts();

  const [resetReportsMutation, { loading: isResettingReports }] = useMutation<
    ResetReportsMutation,
    ResetReportsMutationVariables
  >(RESET_REPORTS, {
    onCompleted() {
      alerts.success(
        `Successfully queued ${reportIds.length} reports to be reset.`
      );
    },
    onError(error) {
      alerts.error("We encountered an error resetting the reports.", error);
    },
  });

  const resetReports = useCallback(async () => {
    await resetReportsMutation({
      variables: {
        input: {
          encampStatus,
          organizationStatus,
          reportIds,
          safetyOverride,
          untouchedSteps,
        },
      },
    });
    onSubmit();
  }, [
    resetReportsMutation,
    encampStatus,
    organizationStatus,
    reportIds,
    safetyOverride,
    untouchedSteps,
    onSubmit,
  ]);

  return (
    <ResetReportsButton
      loading={isResettingReports}
      onClick={resetReports}
      disabled={props.disabled}
      variant="contained"
    />
  );
};

type ButtonProps = Pick<
  LoadingButtonProps,
  "onClick" | "loading" | "disabled" | "variant" | "fullWidth"
>;
export const ResetReportsButton = (props: ButtonProps) => {
  return (
    <LoadingButton
      variant={props.variant ?? "outlined"}
      size="small"
      color="info"
      loadingPosition="start"
      startIcon={<Restore />}
      {...props}
    >
      Reset reports
    </LoadingButton>
  );
};
