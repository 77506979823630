import Launch from "@mui/icons-material/Launch";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem, GridSortModel } from "@mui/x-data-grid-premium";
import { DataGrid, DataGridProps } from "components/DataGrid";
import { prettyPrintModelIssueModelName } from "encamp-shared/src/utils/prettyPrintModelIssue";
import { Issue } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useState } from "react";

export type IssueWithUrl = { issue: Issue; url: string | null };

type IssuesDataGridProps = Partial<DataGridProps<IssueWithUrl>> & {
  issues: IssueWithUrl[];
};
export const IssuesDataGrid = ({ issues, ...props }: IssuesDataGridProps) => {
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "reportsAffected", sort: "desc" },
  ]);

  return (
    <DataGrid
      noRowsMessage="No issues found!"
      rowCount={issues.length}
      getRowId={({ issue }) => `${issue.schemaPath}-${issue.key}`}
      isRowSelectable={() => false}
      onRowClick={(event) => {
        if (event.row.url) {
          window.location.href = event.row.url;
        }
      }}
      getRowClassName={(params) => (!params.row.url ? "non-clickable-row" : "")}
      sx={{
        Height: "200px",
        "& .non-clickable-row": {
          cursor: "default !important",
        },
      }}
      pagination
      paginationMode="client"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="client"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      {...props}
      rows={issues}
      columns={[
        {
          field: "modelName",
          headerName: "Data Type",
          flex: 1,
          valueGetter: ({
            row: {
              issue: { modelName },
            },
          }) => prettyPrintModelIssueModelName(modelName),
          sortable: false,
        },

        {
          field: "message",
          headerName: "Issue",
          flex: 1,
          valueGetter: ({
            row: {
              issue: { message },
            },
          }) => message,
          sortable: false,
        },

        {
          field: "actions",
          type: "actions",
          getActions: ({ row: { issue, url } }) => [
            url ? (
              <GridActionsCellItem
                key="open"
                onClick={() => {
                  window.open(url, "_blank");
                }}
                label={`View ${prettyPrintModelIssueModelName(
                  issue.modelName
                )}`}
                icon={
                  <Tooltip
                    title={`View ${prettyPrintModelIssueModelName(
                      issue.modelName
                    )}`}
                  >
                    <Launch />
                  </Tooltip>
                }
              />
            ) : (
              <></>
            ),
          ],
        },
      ]}
    />
  );
};
