import { useMutation } from "@apollo/client";

import { gql } from "generated-graphql";
import { useCallback, useMemo, useState } from "react";
import { StateFieldInfoInput } from "generated-graphql/graphql";
import { DocumentInput2 } from "generated-graphql/graphql";
import { useUploadFiles } from "./useDocumentUpload";

const REPLACE_FACILITY_STATE_FIELD_DOCUMENTS_MUTATION = gql(`
  mutation ReplaceFacilityStateFieldDocuments($documents: [DocumentInput2!]!, $facilityId: ID!, $stateFieldInfo: StateFieldInfoInput!) {
    replaceFacilityStateFieldDocuments(documents: $documents, facilityId: $facilityId, stateFieldInfo: $stateFieldInfo) {
      id
    }
  }
`);

export function useReplaceFacilityStateFieldDocuments() {
  const [loading, setLoading] = useState(false);
  const [replaceFacilityStateFieldDocumentsMutation] = useMutation(
    REPLACE_FACILITY_STATE_FIELD_DOCUMENTS_MUTATION
  );

  const uploadFiles = useUploadFiles();

  const replaceFacilityStateFieldDocuments = useCallback(
    async ({
      documentsWithFile,
      facilityId,
      stateFieldInfo,
    }: {
      documentsWithFile: { file?: File; documentData: DocumentInput2 }[];
      facilityId: string;
      stateFieldInfo: StateFieldInfoInput;
    }) => {
      setLoading(true);
      try {
        const documentKeys = await uploadFiles(documentsWithFile);

        await replaceFacilityStateFieldDocumentsMutation({
          variables: {
            documents: documentsWithFile.map((doc, index) => ({
              ...doc.documentData,
              storageLink: documentKeys[index],
            })),
            facilityId,
            stateFieldInfo,
          },
        });
      } catch (error) {
        console.error("Error replacing facility state field documents", error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [replaceFacilityStateFieldDocumentsMutation, uploadFiles]
  );

  return useMemo(
    () => ({ replaceFacilityStateFieldDocuments, loading }),
    [replaceFacilityStateFieldDocuments, loading]
  );
}
