import { TIER_II_MANAGER_STATES } from "../constants/states";
import { currentTierIIReportingYear } from "../constants/tierii";
import { Facility, FacilityAlternateIdInput } from "../generated-graphql";
import { FacilityAlternateId } from "../prisma/generated/schema";

export enum FacilityAlternateIdKnownKind {
  AIR = "AIR",
  ASMARK = "ASMARK",
  CERS = "CERS",
  CERS_BUSINESS = "CERS_BUSINESS",
  CLEAN_EARTH = "CLEAN_EARTH",
  CLEAN_EARTH_EPA = "CLEAN_EARTH_EPA",
  CLEAN_HARBORS = "CLEAN_HARBORS",
  CLEAN_HARBORS_EPA = "CLEAN_HARBORS_EPA",
  DOT = "DOT",
  EPA = "EPA",
  EPLAN = "EPLAN",
  FRS = "FRS",
  GHG = "GHG",
  ISO_CERTIFICATION = "ISO_CERTIFICATION",
  NPDES = "NPDES",
  PSM = "PSM",
  RCRAINFO = "RCRAINFO",
  RMP = "RMP",
  SAFETY_KLEEN = "SAFETY KLEEN",
  SAFETY_KLEEN_EPA = "SAFETY KLEEN EPA",
  STATE = "STATE",
  STEERS_CN = "STEERS_CN",
  STEERS_RN = "STEERS_RN",
  TIER2_AZ = "TIER2_AZ",
  TIER2_CHICAGO = "TIER2_CHICAGO",
  TIER2_KS = "TIER2_KS",
  TIER2_LA = "TIER2_LA",
  TIER2_MANAGER = "TIER2_MANAGER",
  TIER2_MO = "TIER2_MO",
  TIER2_MO_COMPANY = "TIER2_MO_COMPANY",
  TIER2_NE = "TIER2_NE",
  TIER2_NJ = "TIER2_NJ",
  TIER2_NYC = "TIER2_NYC",
  TIER2_SD = "TIER2_SD",
  TIER2_WA = "TIER2_WA",
  TRI = "TRI",
}

/**
 * This should stay in sync with hasStateId
 * @param state
 * @returns boolean indicating if a state id is required for the facility
 */
export const stateRequiresStateId = (state?: string | null) => {
  const statesRequired = [
    ...TIER_II_MANAGER_STATES,
    "AZ",
    "CA",
    "KS",
    "LA",
    "MO",
    "NE",
    "NJ",
    "SD",
    "TX",
    "WA",
  ];
  return state && statesRequired.includes(state);
};

export const hasStateId = (
  state: string | null,
  altIds: Pick<FacilityAlternateId, "type" | "value">[]
): boolean => {
  if (
    state &&
    (TIER_II_MANAGER_STATES as ReadonlyArray<string>).includes(state)
  ) {
    return !!altIds.find(
      (id) => id.type === FacilityAlternateIdKnownKind.TIER2_MANAGER
    );
  }
  switch (state) {
    case "AZ":
      return !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_AZ
      );
    case "CA":
      const caIds = [
        FacilityAlternateIdKnownKind.CERS,
        FacilityAlternateIdKnownKind.CERS_BUSINESS,
      ];
      const cersIds = altIds.filter(
        (id) =>
          caIds.includes(id.type as FacilityAlternateIdKnownKind) &&
          id.value.length
      );

      return caIds.length === cersIds.length;

    case "KS":
      return !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_KS
      );

    case "LA":
      return !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_LA
      );

    case "MO":
      const hasMoCompany = !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_MO_COMPANY
      );

      const hasCurRY = !!altIds.find(
        (id) =>
          id.type.includes("MO") &&
          id.type.includes(currentTierIIReportingYear.toString())
      );
      return hasMoCompany && hasCurRY;

    case "NE":
      return !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_NE
      );

    case "NJ":
      return !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_NJ
      );

    case "SD":
      return !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_SD
      );

    case "TX":
      const txIds = [
        FacilityAlternateIdKnownKind.STEERS_CN,
        FacilityAlternateIdKnownKind.STEERS_RN,
      ];
      const actualIds = altIds.filter(
        (id) =>
          txIds.includes(id.type as FacilityAlternateIdKnownKind) &&
          id.value.length
      );

      return txIds.length === actualIds.length;

    case "WA":
      return !!altIds.find(
        (id) => id.type === FacilityAlternateIdKnownKind.TIER2_WA
      );

    default:
      return false;
  }
};

export function cleanAlternateIdKind(
  kind: FacilityAlternateIdKnownKind | string
) {
  // This may include additional logic in the future such as:
  // - turning spaces into underscores
  // - converting known aliases to standard values
  return kind.toUpperCase();
}

/**
 * @deprecated use findAlternateId from facilityAlternateId/findAlternateId instead
 */
export function findAlternateId(
  alternateIds: FacilityAlternateIdInput[] | undefined,
  inputKind: FacilityAlternateIdKnownKind | string
): FacilityAlternateIdInput | undefined {
  const kind = cleanAlternateIdKind(inputKind);
  const match = alternateIds?.find((x) => x.kind === kind);

  return match;
}

export function findAlternateIdFromFacilityOrThrow(
  facility: Facility,
  inputKind: FacilityAlternateIdKnownKind | string
): FacilityAlternateIdInput {
  const alternateIds: FacilityAlternateIdInput[] =
    facility.alternateIds?.map((x) => ({
      id: x.id ?? "",
      kind: x.kind ?? "",
    })) ?? [];
  return findAlternateIdOrThrow(alternateIds, inputKind);
}

export function findAlternateIdOrThrow(
  alternateIds: FacilityAlternateIdInput[] | undefined,
  inputKind: FacilityAlternateIdKnownKind | string
): FacilityAlternateIdInput {
  const kind = cleanAlternateIdKind(inputKind);
  const match = alternateIds?.find((x) => x.kind === kind);
  if (!match) {
    throw new Error(`AlternateId ${kind} not found`);
  }

  return match;
}

export function toFacilityAlternateId(
  inputKind: FacilityAlternateIdKnownKind | string,
  id: string
): FacilityAlternateIdInput {
  const kind = cleanAlternateIdKind(inputKind);

  const alternateId: FacilityAlternateIdInput = {
    kind,
    id,
  };

  return alternateId;
}
