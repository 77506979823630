import {
  FormControl,
  InputAdornment,
  styled,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { isNil } from "lodash";
import React, { ChangeEvent, useMemo, useState } from "react";
import {
  Controller,
  FieldValues,
  RegisterOptions,
  UseControllerProps,
} from "react-hook-form";
import { ErrorDisplay } from "./ErrorDisplay";
import { TextWithTooltip } from "./TextWithTooltip";

export type FormTextFieldProps<T extends FieldValues> = {
  label: string | React.ReactNode;
  tooltip?: string;
  preserveEmptyString?: boolean;
  textFieldProps?: TextFieldProps;
  intOnly?: boolean;
  helperText?: string;
  sx?: SxProps;
  fullWidth?: boolean;
  maxLength?: number;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  InputProps?: TextFieldProps["InputProps"];
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  rules?: RegisterOptions;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  hideErrors?: boolean;
  type?: React.HTMLInputTypeAttribute;
  show1Pass?: boolean;
} & Omit<UseControllerProps<T>, "name"> &
  (
    | { name: UseControllerProps<T>["name"]; readOnly?: never }
    | { name?: never; readOnly: true }
  );

const StyledTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    whiteSpace: "normal", // Allow wrapping
    overflow: "visible",
  },
  "& .MuiInputLabel-shrink": {
    whiteSpace: "nowrap",
  },
});

export const FormTextField = <T extends FieldValues>({
  name,
  label,
  tooltip,
  control,
  preserveEmptyString,
  textFieldProps,
  intOnly,
  readOnly,
  defaultValue,
  helperText,
  sx,
  fullWidth = true,
  maxLength,
  inputProps,
  InputProps,
  InputLabelProps,
  rules: rules,
  disabled,
  onBlur,
  hideErrors,
  type,
  show1Pass,
}: FormTextFieldProps<T>) => {
  const labelWithTooltip = useMemo(
    () =>
      tooltip ? <TextWithTooltip text={label} tooltip={tooltip} /> : label,
    [label, tooltip]
  );

  // This state keeps track of whether this field is focused
  const [inputFocus, setInputFocus] = useState(false);

  if (readOnly) {
    return (
      <FormControl fullWidth={fullWidth}>
        <TextField
          {...textFieldProps}
          value={defaultValue}
          disabled
          label={label}
          sx={sx}
          inputProps={{
            ...inputProps,
            "data-form-type": "other",
            "data-1p-ignore": true,
          }}
        />
        {/* Displays error messages and helper text */}
        <ErrorDisplay helperText={helperText} hideErrors={true} />
      </FormControl>
    );
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field, fieldState: { error } }) => (
          <>
            <StyledTextField
              {...textFieldProps}
              {...field}
              value={preprocessValue(field.value, defaultValue)}
              name={name}
              label={labelWithTooltip}
              type={type}
              error={!!error}
              disabled={disabled}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                setInputFocus(false);
                onBlur?.(event);
              }}
              onFocus={() => setInputFocus(true)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (!intOnly || event.target.value === "") {
                  field.onChange(
                    !preserveEmptyString && event.target.value === ""
                      ? null
                      : event.target.value
                  );
                  return;
                }

                const value = event.target.value;
                const intValue = Number(value);
                if (!Number.isNaN(intValue)) {
                  field.onChange(Math.floor(intValue));
                }
              }}
              inputProps={{
                ...inputProps,
                ...(maxLength ? { maxLength } : {}),
                "data-form-type": "other",
                ...(show1Pass ? {} : { "data-1p-ignore": true }),
              }}
              InputLabelProps={InputLabelProps}
              InputProps={{
                endAdornment:
                  inputFocus && maxLength && field.value ? (
                    <InputAdornment position="end">
                      {`${field.value.length}/${maxLength}`}
                    </InputAdornment>
                  ) : null,
                ...InputProps,
              }}
            />
            {/* Displays error messages and helper text */}
            <ErrorDisplay
              error={error}
              helperText={helperText}
              hideErrors={hideErrors}
            />
          </>
        )}
      />
    </FormControl>
  );
};

function preprocessValue(value: string, defaultValue: string | undefined) {
  if (isNil(value)) {
    return defaultValue ?? "";
  }

  return value;
}
