import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Box, Button, Link, Stack, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
  EditMode,
  FormState,
} from "components/AddEditDocumentDialog";
import { useDocumentViewer } from "components/DocumentViewer";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import {
  DocumentType,
  DocumentsQuery,
  JobStatus,
  JobTask,
  TierIiReportEncampStatus,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import {
  OmnisearchGridColDef,
  useOmnisearchDatagrid,
} from "hooks/useOmnisearchDatagridSettings";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useTenant } from "hooks/useTenant";
import { startCase } from "lodash";
import { client } from "providers/apollo";
import { useCallback, useState } from "react";
import { documentTypeToLabel } from "util/constants";
import { prettyPrintDateMed } from "util/dates";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { DocumentsBulkActionsMenu } from "./DocumentsBulkActionsMenu";
import Download from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

export type Row = DocumentsQuery["documents"]["items"][number];

export const DOCUMENT_FRAGMENT = gql(`
  fragment DocumentFields on Document {
    id
    tenantId
    title
    documentType
    fileExtension
    storageLink
    createdAt
    report {
      encampStatus
    }
    facilities {
      id
      name
    }
    documentTags {
      name
      id
    }
    chemicalDocument {
      chemical {
        name
        id
      }
    }
    job {
      id
      status
      task
    }
  }
`);

export const DOCUMENTS = gql(`
  query Documents($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    documents(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...DocumentFields
      }
      count
    }
  }
`);

export const DOCUMENT = gql(`
  query Document($id: ID!) {
    document(id: $id) {
      ...DocumentFields
    }
  }
`);

export function Documents() {
  const { tenantId } = useTenant();
  const [selectedDocument, setSelectedDocument] = useState<Row | undefined>(
    undefined
  );
  const [selectedRows, setSelectedRows] = useState<Row[]>([]);

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>(defaultAddEditDocumentDialogState);

  useBreadcrumb([{ label: "Documents" }]);

  const { fetchAndHandleDownload } = useDocumentViewer();
  const apiRef = useGridApiRef();

  const { setOmnisearch } = useOmnisearchDatagrid();

  const searchByFacility = useCallback(
    (facilityName: string) => {
      setOmnisearch(
        /\s/.test(facilityName)
          ? `facility:"${facilityName}"`
          : `facility:${facilityName}`
      );
    },
    [setOmnisearch]
  );

  const { documentId } = useParams();
  useQuery(DOCUMENT, {
    variables: {
      id: documentId ?? "",
    },
    skip: !documentId,
    onCompleted: (data) => {
      if (data?.document) {
        openEditDialog(data.document);
      }
    },
  });

  const handleClickChemicalLink =
    (params: { row: Row }) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (params.row.chemicalDocument?.length) {
        e.preventDefault();
        e.stopPropagation();
        const chemicalDocument = params.row.chemicalDocument[0];
        const chemical = chemicalDocument.chemical;
        const link = `/o/${tenantId}/chemicals/catalog/chemicals/${chemical.id}`;
        window.open(link, "_blank", "noopener,noreferrer");
      }
    };

  const nonTerminalJobStatuses = [
    JobStatus.Queued,
    JobStatus.Running,
    JobStatus.Provisioning,
    JobStatus.ProvisioningEcsTask,
    JobStatus.ShouldCancel,
    JobStatus.Cancelling,
  ];
  /**
   * A document may be used for file extraction if:
   * it has no chemical document  AND there are no non-terminal (queued/running) file extraction jobs
   */
  const isRowSelectableForFileExtraction = (row: Row): boolean => {
    if ((row.chemicalDocument?.length ?? 0) > 0) {
      return false;
    }

    // Check if the document has a running SDS Parse job
    // and yes `job` should really be plural
    const hasAProcessingSDSJob =
      row.job?.some(
        (job) =>
          job.task == JobTask.FileExtraction &&
          nonTerminalJobStatuses.includes(job.status)
      ) ?? false;

    return !hasAProcessingSDSJob;
  };
  // Grid Column Definitions
  const columns: OmnisearchGridColDef<Row>[] = [
    {
      field: "title",
      headerName: "Name",
      flex: 0.75,
      sortable: true,
      renderCell(params) {
        return (
          <Box
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.title}
          </Box>
        );
      },
    },
    {
      field: "documentType",
      headerName: "Type",
      flex: 0.5,
      sortable: true,
      valueGetter(params) {
        return documentTypeToLabel(params.row.documentType);
      },
      filterKeyType: "enum",
      enumValues: Object.values(DocumentType),
      enumPresentationFunction: (enumValue) => startCase(enumValue),
    },
    {
      field: "fileExtension",
      headerName: "File Type",
      flex: 0.25,
      sortable: true,
    },
    {
      field: "facility",
      headerName: "Facility",
      filterKeyType: "facility",
      flex: 0.5,
      sortable: false,
      renderCell(params) {
        const facilities = params.row.facilities ?? [];

        return (
          <Stack>
            {facilities.slice(0, 3).map((facility) => (
              <Typography
                key={facility.id}
                variant="body2"
                onClick={() => searchByFacility(facility.name)}
              >
                {facility.name}
              </Typography>
            ))}
            {facilities.length > 3 && (
              <Typography variant="body2">...</Typography>
            )}
          </Stack>
        );
      },
    },
    {
      field: "chemicalDocument",
      headerName: "Attached To",
      flex: 0.5,
      sortable: false,
      renderCell(params) {
        return (
          <Box
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <Link
              target="_blank"
              onClick={handleClickChemicalLink({ row: params.row })}
            >
              {params.row.chemicalDocument?.[0]?.chemical?.name}
            </Link>
          </Box>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Uploaded On",
      flex: 0.25,
      sortable: true,
      valueGetter(params) {
        return prettyPrintDateMed(params.row.createdAt);
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 125,
      align: "left",
      getActions: ({ row }) => {
        const actions = [
          <Tooltip title="Edit Document" key={1}>
            <GridActionsCellItem
              onClick={() => openEditDialog(row)}
              label="Edit Document"
              icon={<Edit />}
            />
          </Tooltip>,
          <Tooltip title="Download Document" key={2}>
            <GridActionsCellItem
              onClick={async () =>
                await fetchAndHandleDownload({
                  documentId: row.id,
                })
              }
              label="Download Document"
              icon={<Download />}
            />
          </Tooltip>,
        ];

        if (
          row.report == null ||
          row.report.encampStatus !== TierIiReportEncampStatus.FilingComplete
        ) {
          actions.push(
            <Tooltip title="Remove Document" key={1}>
              <GridActionsCellItem
                onClick={() => {
                  setSelectedDocument(row);
                  setShowConfirmDeleteDialog(true);
                }}
                label="Remove Document"
                icon={<Delete />}
              />
            </Tooltip>
          );
        }

        return actions;
      },
    },
  ];

  const openEditDialog = (row: Row) => {
    setAddEditDialogState((state) => ({
      ...state,
      open: true,
      mode: EditMode.EDIT,
      step: FormState.FORM,
      documentId: row.id,
      title: row.title,
      documentType: row.documentType,
      fileExtension: row.fileExtension,
      facilities: row.facilities ?? [],
      documentTags: row.documentTags ?? undefined,
    }));
  };

  return (
    <Box sx={{ pt: 3 }}>
      <ConfirmDeleteDialog
        open={showConfirmDeleteDialog}
        selectedDocument={selectedDocument}
        onClose={() => {
          setShowConfirmDeleteDialog(false);
          setSelectedDocument(undefined);
        }}
      />
      <OmnisearchDataGrid
        apiRef={apiRef}
        onSelectedRowsChanged={setSelectedRows}
        columns={columns}
        dataQuery={DOCUMENTS}
        isRowSelectable={({ row }) => isRowSelectableForFileExtraction(row)}
        disableRowSelectionOnClick={true}
        initialSortModel={[{ field: "createdAt", sort: "desc" }]}
        defaultSearch={`tenantId:${tenantId}`}
        excludeFilterColumns={["createdAt"]}
        getItems={(data) => data.documents.items}
        getCount={(data) => data.documents.count ?? 0}
        showFavorites={true}
        noDataMessage="No documents have been added to this organization yet."
        onRowClick={({ row }) => openEditDialog(row)}
        initialState={{
          pinnedColumns: {
            right: ["actions"],
          },
        }}
        commandButtons={[
          <Button
            key="add-document-button"
            variant="contained"
            onClick={() => {
              setAddEditDialogState((state) => ({
                ...state,
                open: true,
                mode: EditMode.ADD,
              }));
            }}
          >
            Add Document
          </Button>,
          <DocumentsBulkActionsMenu
            key={"documents-bulk-actions-menu"}
            documents={selectedRows}
            apiRef={apiRef}
          />,
        ]}
        rowHeight="auto"
        initialPageSize={50}
        additionalFilterColumns={[
          {
            header: "Tag",
            key: "tags",
          },
        ]}
      />

      {addEditDialogState.open && tenantId && (
        <AddEditDocumentDialog
          tenantId={tenantId}
          dialogState={addEditDialogState}
          setDialogState={setAddEditDialogState}
          showFacilityPicker={true}
          onSubmit={() => {
            client.refetchQueries({ include: [DOCUMENTS] });
          }}
          refetchQueries={[DOCUMENTS]}
          showTagPicker
        />
      )}
    </Box>
  );
}
