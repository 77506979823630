import Remove from "@mui/icons-material/Remove";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useUpdateActivities } from "hooks/activities";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { useBulkActionContext } from ".";

export function useUnassignActivities() {
  const [updateActivities, { loading }] = useUpdateActivities();
  const unassign = useCallback(
    (activityIds: string[]) => {
      updateActivities({
        variables: {
          input: {
            assigneeId: null,
          },
          ids: activityIds,
          allowPartialFailure: true,
        },
      });
    },
    [updateActivities]
  );

  return {
    unassign,
    loading,
  };
}

export function UnassignMenuItem() {
  const { selectedRows, onClose } = useBulkActionContext();
  const shouldDisableAssign = useMemo(() => {
    return !selectedRows.every((row) => row.activityId);
  }, [selectedRows]);

  const { unassign, loading: isUpdatingActivities } = useUnassignActivities();

  const handleUnassignUser = useCallback(async () => {
    const activityIds = compact(selectedRows.map((row) => row.activityId));
    await unassign(activityIds);
    onClose();
  }, [selectedRows, unassign, onClose]);

  return (
    <MenuItem
      disabled={shouldDisableAssign || isUpdatingActivities}
      onClick={handleUnassignUser}
    >
      <ListItemIcon>
        <Remove />
      </ListItemIcon>
      <ListItemText>Unassign</ListItemText>
    </MenuItem>
  );
}
