import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import Restore from "@mui/icons-material/Restore";
import { useBulkActionContext } from ".";
import { ResetReportsFormDialog } from "components/ResetReportsFormDialog";

export function ResetReportsMenuItem() {
  const { setDialogState } = useBulkActionContext();

  return (
    <MenuItem onClick={() => setDialogState("resetReports")}>
      <ListItemIcon>
        <Restore />
      </ListItemIcon>
      <ListItemText>Reset Reports</ListItemText>
    </MenuItem>
  );
}

export const ResetReportsModal = () => {
  const { dialogState, onClose, selectedRows } = useBulkActionContext();

  const selectedReportIds = selectedRows
    .filter((row) => !!row.tierIIReportId)
    .map((row) => row.tierIIReportId) as string[];

  return (
    <>
      {dialogState === "resetReports" && (
        <ResetReportsFormDialog
          onClose={onClose}
          selectedReportIds={selectedReportIds}
        />
      )}
    </>
  );
};
