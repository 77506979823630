import { MutationHookOptions, useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import {
  DeleteDocumentMutation,
  RemoveDocumentFromActivityMutation,
  RemoveDocumentFromReportMutation,
} from "generated-graphql/graphql";

const DELETE_DOCUMENT_MUTATION = gql(`
  mutation DeleteDocument($id: ID!, $retainAsset: Boolean) {
    deleteDocument(id: $id, retainAsset: $retainAsset) {
      id
      reportId
    }
  }
`);

type DeleteDocumentVariables = {
  id: string;
};

export function useDeleteDocumentMutation(
  options?: MutationHookOptions<DeleteDocumentMutation, DeleteDocumentVariables>
) {
  return useMutation(DELETE_DOCUMENT_MUTATION, options);
}

const REMOVE_DOCUMENT_FROM_REPORT_MUTATION = gql(`
  mutation RemoveDocumentFromReport($documentId: ID!, $reportId: ID!) {
    removeDocumentFromReport(documentId: $documentId, reportId: $reportId)
  }
`);

type RemoveDocumentFromReportVariables = {
  documentId: string;
  reportId: string;
};

export function useRemoveDocumentFromReportMutation(
  options?: MutationHookOptions<
    RemoveDocumentFromReportMutation,
    RemoveDocumentFromReportVariables
  >
) {
  return useMutation(REMOVE_DOCUMENT_FROM_REPORT_MUTATION, options);
}

export const REMOVE_DOCUMENT_FROM_ACTIVITY_MUTATION = gql(`
  mutation RemoveDocumentFromActivity($activityId: ID!, $documentId: ID!) {
    removeDocumentFromActivity(activityId: $activityId, documentId: $documentId) {
      activityId
      documentId
    }
  }`);

type RemoveDocumentFromActivityVariables = {
  activityId: string;
  documentId: string;
};
export function useRemoveDocumentFromActivityMutation(
  options?: MutationHookOptions<
    RemoveDocumentFromActivityMutation,
    RemoveDocumentFromActivityVariables
  >
) {
  return useMutation(REMOVE_DOCUMENT_FROM_ACTIVITY_MUTATION, options);
}
