import { grey } from "@mui/material/colors";
import { hmbpSectionDisplay } from "encamp-shared/src/hmbp";
import { useCallback, useState } from "react";
import {
  hmbpDocumentTypes,
  useCAStepStatus,
  useHmbpDynamicFields,
  useHmbpIssues,
  useHmbpStateFieldMutation,
  useHmbpValidator,
} from "../../useHmbp";
import { BaseRow, SectionProps } from "./common";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useAlerts } from "components/Alerts/AlertProvider";
import {
  ProgramArea,
  TierIiReportCaStep,
} from "encamp-shared/src/generated-graphql/oak-resolver-types";
import { useReplaceFacilityStateFieldDocuments } from "hooks/useReplaceFacilityStateFieldDocuments";
import { useTenant } from "hooks/useTenant";
import { useReport } from "../../useReport";
import { useTouchReportCAStepMutation } from "../../useTouchReportCAStepMutation";
import { HmbpDocumentForm } from "./HmbpDocumentForm";
import { FormSubmitData as HmbpDocumentFormSubmitData } from "./HmbpDocumentForm/types";

export function DocumentSection({ section, recommended }: SectionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { tenantId } = useTenant();
  const { data: reportData } = useReport();
  const alerts = useAlerts();
  const title = hmbpSectionDisplay[section];
  const issues = useHmbpIssues(section);
  const fields = useHmbpDynamicFields(section);
  const documentIdsField = fields.find((field) =>
    field.key.toLowerCase().includes("documentids")
  );

  const { data, refetch: refetchReport } = useReport();
  const hmbpSection = section as unknown as TierIiReportCaStep;
  const status = useCAStepStatus(hmbpSection);
  const { handleSave: handleTouch, loading: touchLoading } =
    useTouchReportCAStepMutation(
      data?.tierIIReport.id ?? "",
      data?.tierIIReport.touchedCASteps ?? [],
      hmbpSection
    );

  const validator = useHmbpValidator(section);
  const { mutation: mutateStateFields, loading: loadingStateFields } =
    useHmbpStateFieldMutation();
  const {
    replaceFacilityStateFieldDocuments,
    loading: loadingReplaceDocuments,
  } = useReplaceFacilityStateFieldDocuments();

  const loading = loadingStateFields || loadingReplaceDocuments || touchLoading;

  const handleSave = useCallback(
    async (data: HmbpDocumentFormSubmitData) => {
      try {
        await mutateStateFields(data.stateFields);
        await handleTouch();

        await replaceFacilityStateFieldDocuments({
          documentsWithFile: data.documentUploads.map((document) => ({
            file: document.file,
            documentData: {
              id: document.document?.id,
              tenantId,
              title: document.document?.title ?? document.file?.name,
              documentType: hmbpDocumentTypes[section],
              authoredAt: document.document?.authoredAt ?? null,
              description: document.document?.description ?? null,
            },
          })),
          facilityId: reportData?.tierIIReport.facility.id ?? "",
          stateFieldInfo: {
            key: documentIdsField?.key ?? "",
            type: ProgramArea.Epcra,
            jurisdiction: documentIdsField?.jurisdiction ?? "",
          },
        });
        alerts.success("State information saved successfully.");
        refetchReport();
      } catch (err) {
        alerts.error("Error saving state information.");
        console.error("Error saving state information.", err);
      }
    },
    [
      alerts,
      documentIdsField?.jurisdiction,
      documentIdsField?.key,
      handleTouch,
      mutateStateFields,
      replaceFacilityStateFieldDocuments,
      reportData?.tierIIReport.facility.id,
      section,
      tenantId,
      refetchReport,
    ]
  );

  return (
    <>
      <BaseRow
        title={title}
        recommended={recommended ?? false}
        icon={<UploadFileIcon sx={{ color: grey[400] }} />}
        onClick={() => {
          setIsOpen(true);
        }}
        section={section}
        issues={issues}
        status={status}
      />
      {isOpen && (
        <HmbpDocumentForm
          title={title}
          issues={issues}
          fields={fields}
          loading={loading}
          validator={validator}
          handleClose={() => setIsOpen(false)}
          handleSave={handleSave}
        />
      )}
    </>
  );
}
