import { Box } from "@mui/material";
import { IssuesDataGrid, IssueWithUrl } from "components/IssuesDataGrid";
import { ActivityType, GetTierIiReportQuery } from "generated-graphql/graphql";

type Activity = NonNullable<
  GetTierIiReportQuery["tierIIReport"]["currentWorkflow"]
>["activities"][0];

/**
 * Use this component to define a custom display for activity errors.
 *
 * For example, presubmission activities have issues as part of their
 * output so we have a custom display for those.
 */
export const ActivityErrorDisplay = ({ activity }: { activity: Activity }) => {
  if (activity.type === ActivityType.Presubmission) {
    const issues = (activity.output?.issues ?? []) as IssueWithUrl[];
    return (
      <Box sx={{ flex: 1 }} gap={1}>
        <IssuesDataGrid issues={issues} />
      </Box>
    );
  }

  return <></>;
};
