import { grey } from "@mui/material/colors";
import { DynamicFormDialog } from "components/DynamicFormDialog";
import { hmbpSectionDisplay } from "encamp-shared/src/hmbp";
import { useState, PropsWithChildren } from "react";
import {
  useCAStepStatus,
  useHmbpDynamicFields,
  useHmbpIssues,
  useHmbpStateFieldMutation,
  useHmbpValidator,
} from "../../useHmbp";
import { SectionProps, BaseRow } from "./common";

import ListAltIcon from "@mui/icons-material/ListAlt";
import { useAlerts } from "components/Alerts/AlertProvider";
import { TierIiReportCaStep } from "generated-graphql/graphql";
import { useTouchReportCAStepMutation } from "../../useTouchReportCAStepMutation";
import { useReport } from "../../useReport";

export function FormSection({
  section,
  children,
}: PropsWithChildren<SectionProps>) {
  const [isOpen, setIsOpen] = useState(false);

  const title = hmbpSectionDisplay[section];
  const fields = useHmbpDynamicFields(section);
  const issues = useHmbpIssues(section);
  const validator = useHmbpValidator(section);
  const { mutation } = useHmbpStateFieldMutation();
  const alerts = useAlerts();

  const { data, refetch: refetchReport } = useReport();
  const hmbpSection = section as unknown as TierIiReportCaStep;
  const status = useCAStepStatus(hmbpSection);
  const { handleSave: handleTouch, loading: touchLoading } =
    useTouchReportCAStepMutation(
      data?.tierIIReport.id ?? "",
      data?.tierIIReport.touchedCASteps ?? [],
      hmbpSection
    );

  return (
    <>
      <BaseRow
        title={title}
        recommended={true} // TODO: calculate recommended status based on section enum
        icon={<ListAltIcon sx={{ color: grey[400] }} />}
        onClick={() => setIsOpen(true)}
        section={section}
        issues={issues}
        status={status}
      />
      <DynamicFormDialog
        title={title}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        persistedIssues={issues}
        fields={fields}
        validator={validator}
        onSubmit={async (data) => {
          try {
            await handleTouch();
            await mutation(data);
            setIsOpen(false);
            refetchReport();
            alerts.success("State information saved successfully.");
          } catch (err) {
            alerts.error("Error saving state information.");
          }
        }}
      >
        {children}
      </DynamicFormDialog>
    </>
  );
}
