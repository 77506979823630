import AccountCircle from "@mui/icons-material/AccountCircle";
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { hasValue } from "encamp-shared/src/utils/hasValue";
import { ExecutionPlanType } from "generated-graphql/graphql";
import { useUpdateActivities } from "hooks/activities";
import { useCallback, useMemo } from "react";
import { useBulkActionContext } from ".";

export function SetToManual() {
  const { selectedRows, onClose } = useBulkActionContext();
  const alerts = useAlerts();
  const [updateActivities] = useUpdateActivities();

  const shouldDisable = useMemo(() => {
    return selectedRows.some((row) => !row.activityId);
  }, [selectedRows]);

  const setPortalToManual = useCallback(async () => {
    try {
      await updateActivities({
        variables: {
          ids: selectedRows.map((row) => row.activityId).filter(hasValue),
          input: {
            executionPlan: ExecutionPlanType.Human,
          },
          allowPartialFailure: true,
        },
      });
      alerts.success(`Set facilities to manual mode`);
    } catch (error) {
      alerts.error(`Error: unable to set facilities to manual `);
    } finally {
      onClose();
    }
  }, [updateActivities, selectedRows, alerts, onClose]);

  return (
    <Tooltip title="Stop geppetto and take over portal run">
      <MenuItem disabled={shouldDisable} onClick={setPortalToManual}>
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText>Set Portal to Manual</ListItemText>
      </MenuItem>
    </Tooltip>
  );
}
