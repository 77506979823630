import { Link, Stack, Typography } from "@mui/material";
import { HmbpSection } from "encamp-shared/src/hmbp";
import { ReportFloatingBar } from "../ReportFloatingBar";
import { useShowSection } from "../useHmbp";
import { useStateInfoIssues } from "../useReport";
import {
  DocumentSection,
  FormSection,
  SectionContainer,
  UndergroundStorageTanks,
} from "./hmbpComponents";

export function HmbpForm() {
  const showUSTCertificationFinancialResponsibility = useShowSection(
    HmbpSection.USTCertificationOfFinancialResponsibility
  );
  return (
    <>
      <Stack direction="column" flex={1}>
        <Stack spacing={3}>
          <SectionContainer title="Facility Information">
            <FormSection section={HmbpSection.FacilityInformation} />
          </SectionContainer>

          <SectionContainer title="Emergency Response and Training Plans">
            <DocumentSection
              section={HmbpSection.EmergencyResponseContingencyPlan}
            />
            <DocumentSection section={HmbpSection.EmployeeTrainingPlan} />
          </SectionContainer>
          {useShowSection(HmbpSection.USTMonitoringSitePlan) && (
            <Stack spacing={3}>
              <SectionContainer
                title="Underground Storage Tanks (UST)"
                caption="Because the facility has USTs, the following forms/uploads are
              required. See below to add the UST documentation required for each
              tank at the facility."
              >
                <FormSection
                  section={HmbpSection.USTFacilityOperatingPermitApplication}
                />
                <DocumentSection
                  section={HmbpSection.USTMonitoringSitePlan}
                  recommended={true}
                />
                {showUSTCertificationFinancialResponsibility && (
                  <DocumentSection
                    section={
                      HmbpSection.USTCertificationOfFinancialResponsibility
                    }
                    recommended={true}
                  />
                )}
                <DocumentSection
                  section={HmbpSection.USTResponsePlan}
                  recommended={true}
                />
                <DocumentSection
                  section={HmbpSection.USTOwnerOperatorWrittenAgreement}
                  recommended={true}
                />
                <DocumentSection
                  section={HmbpSection.USTLetterFromChiefFinancialOfficer}
                  recommended={true}
                />
                <DocumentSection
                  section={
                    HmbpSection.OwnerStatementOfDesignatedUSTOperatorCompliance
                  }
                  recommended={true}
                />
              </SectionContainer>

              <UndergroundStorageTanks />
            </Stack>
          )}
          {useShowSection(
            HmbpSection.RecyclableMaterialsReportDocumentation
          ) && (
            <SectionContainer title="Recyclable Materials Report">
              <DocumentSection
                section={HmbpSection.RecyclableMaterialsReportDocumentation}
              />
            </SectionContainer>
          )}
          <SectionContainer title="Hazardous Waste Tank Closure Certification">
            <DocumentSection
              section={HmbpSection.HazardousWasteTankClosureCertificate}
            />
          </SectionContainer>
          <SectionContainer title="Aboveground Petroleum Storage Act">
            <FormSection section={HmbpSection.APSAFacilityInformation}>
              <Typography variant="caption">
                You must indicate if your tank facility is conditionally exempt
                from having to prepare and implement a Spill Prevention,
                Control, and Countermeasure (SPCC) Plan under APSA. Refer to{" "}
                <Link
                  href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=HSC&sectionNum=25270.4.5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HSC §25270.4.5(b)
                </Link>{" "}
                for details. If you indicate that your facility is conditionally
                exempt, then no additional information is requested on the APSA
                Facility Information. If you indicate that your facility is not
                conditionally exempt, then complete the remaining fields in the
                APSA Facility Information. For assistance with calculating the
                total aboveground storage capacity of petroleum at your
                facility, read the CERS Help Materials for APSA:{" "}
                <Link
                  href="https://osfm.fire.ca.gov/what-we-do/pipeline-safety-and-cupa/certified-unified-program-agency/aboveground-petroleum-storage-act/is-my-facility-regulated-under-the-aboveground-petroleum-storage-act"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Is My Facility Regulated Under APSA?
                </Link>{" "}
                For information on “tanks in underground areas,” please contact
                OSFM at cupa@fire.ca.gov or contact your local regulator for
                assistance.
              </Typography>
            </FormSection>
            <DocumentSection
              section={HmbpSection.AbovegroundPetroleumStorageActDocumentation}
            />
          </SectionContainer>
        </Stack>
      </Stack>
      <ReportFloatingBar issues={useStateInfoIssues()} />
    </>
  );
}
