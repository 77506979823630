import Delete from "@mui/icons-material/Delete";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { ConfirmDialog } from "components/ConfirmDialog";
import { DynamicFieldLayout } from "components/Forms/DynamicFieldLayout";
import { IssueListButton } from "components/Forms/IssueListButton";
import { SaveButton } from "components/SaveButton";
import {
  HmbpSection,
  hmbpSectionDisplay,
  HmbpUndergroundStorageTankSection,
} from "encamp-shared/src/hmbp";
import {
  UndergroundStorageTankFragment,
  UndergroundStorageTankInput,
} from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useMemo, useState } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { hasCriticalIssues } from "util/forms";
import {
  useHmbpUSTankIssues,
  useHmbpUSTankStatus,
  useRemoveUndergroundStorageTank,
  useUndergroundStorageTankDynamicFields,
  useUpsertUndergroundStorageTank,
} from "../../useHmbp";
import { useTouchReportUSTStepMutation } from "../../useTouchReportCAStepMutation";
import { useUndergroundStorageTankInputValidation } from "../../validationHooks/useUndergroundStorageTankValidation";
import { BaseRow } from "./common";

export const TankSection: React.FC<{
  tank: UndergroundStorageTankFragment;
}> = ({ tank }) => {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const openRemoveConfirm = useCallback(() => setShowRemoveConfirm(true), []);
  const cancelRemoveConfirm = useCallback(
    () => setShowRemoveConfirm(false),
    []
  );
  const { remove, loading: removing } = useRemoveUndergroundStorageTank();
  const removeConfirm = useCallback(async () => {
    await remove(tank.id);
    cancelRemoveConfirm();
  }, [cancelRemoveConfirm, remove, tank.id]);

  return (
    <Box
      sx={{
        border: 1,
        borderColor: grey[200],
        borderRadius: 1,
        padding: 0,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ paddingX: "0.5rem", paddingY: "0.875rem" }}
      >
        <Box sx={{ display: "flex", flex: 1, gap: 1, alignItems: "baseline" }}>
          <Typography variant="body2" fontWeight={"500"}>
            {`${tank.nickname} | Tank ID: ${tank.tankId}`}
          </Typography>

          {tank.previouslyReported && (
            <Chip label="Previously reported" size="small" />
          )}
        </Box>
        <Tooltip
          title={
            tank.previouslyReported
              ? "Cannot remove previously reported UST. Contact Encamp Support if assistance is needed."
              : "Remove Tank"
          }
        >
          <span>
            <IconButton
              onClick={openRemoveConfirm}
              disabled={tank.previouslyReported}
            >
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
      <TankFormRow section={HmbpSection.TankInformation} tank={tank} />
      <TankFormRow section={HmbpSection.TankMonitoringPlan} tank={tank} />
      <ConfirmDialog
        open={showRemoveConfirm}
        title="Are you sure you want to remove this tank?"
        msg={
          <Grid container>
            <Grid container>
              <Grid item xs={3}>
                <FormLabel sx={{ fontSize: 12 }}>Tank Nickname</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body1">{tank.nickname}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <FormLabel sx={{ fontSize: 12 }}>Tank ID</FormLabel>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1">{tank.tankId}</Typography>
            </Grid>
          </Grid>
        }
        onCancel={cancelRemoveConfirm}
        onClose={cancelRemoveConfirm}
        loading={removing}
        onConfirm={removeConfirm}
      />
    </Box>
  );
};

const TankFormRow = ({
  section,
  tank,
}: {
  section: HmbpUndergroundStorageTankSection;
  tank: UndergroundStorageTankFragment;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const title = hmbpSectionDisplay[section];
  const issues = useHmbpUSTankIssues(section, tank.id);
  const status = useHmbpUSTankStatus(section, tank.id);

  return (
    <>
      <BaseRow
        title={title}
        recommended={true}
        icon={<ListAltIcon sx={{ color: grey[400] }} />}
        onClick={() => setIsOpen(true)}
        section={section}
        issues={issues}
        status={status}
      />
      {isOpen && (
        <TankFormDialog
          section={section}
          defaultValues={tank}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

const TankFormDialog = ({
  section,
  defaultValues: initialTank,
  onClose,
}: {
  section: HmbpUndergroundStorageTankSection;
  defaultValues: UndergroundStorageTankFragment;
  onClose: () => void;
}) => {
  const defaultValues: UndergroundStorageTankInput = useMemo(
    () => ({ ...initialTank }),
    [initialTank]
  );
  const form = useValidatingForm<UndergroundStorageTankInput>(
    defaultValues,
    useHmbpUSTankIssues(section, initialTank.id),
    useUndergroundStorageTankInputValidation(section)
  );
  const {
    control,
    formState: { isDirty, isSubmitSuccessful, isSubmitting },
    handleSubmit,
    issues,
  } = form;
  const { upsert } = useUpsertUndergroundStorageTank();
  const { touch } = useTouchReportUSTStepMutation(initialTank.id, section);

  const save: SubmitHandler<UndergroundStorageTankInput> = useCallback(
    async (data) => {
      // touch this step
      await touch();
      // save the ust
      await upsert(data);
      // close this dialog
      onClose();
    },
    [onClose, touch, upsert]
  );

  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const hasPendingChanges = isDirty && !isSubmitSuccessful;

  const handleDialogClose = useCallback(() => {
    if (hasPendingChanges) {
      setShowConfirmClose(true);
    } else {
      onClose();
    }
  }, [hasPendingChanges, onClose]);

  const title = hmbpSectionDisplay[section];
  const fields = useUndergroundStorageTankDynamicFields(section);

  return (
    <>
      <Dialog open onClose={handleDialogClose} maxWidth="md">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <DynamicFieldLayout fields={fields} control={control} grouping />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <IssueListButton issues={issues} />
          <Button variant="outlined" onClick={handleDialogClose}>
            Cancel
          </Button>
          <SaveButton
            loading={isSubmitting}
            onClick={handleSubmit(save)}
            disabled={hasCriticalIssues(issues)}
          />
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={showConfirmClose}
        onConfirm={onClose}
        onClose={() => setShowConfirmClose(false)}
        title="Are you sure you want to leave?"
        msg="You have unsaved changes."
      />
    </>
  );
};
