import { ListItem, ListItemText, Stack, Typography } from "@mui/material";
import {
  DocumentType,
  FacilityDocumentsQuery,
} from "generated-graphql/graphql";
import { FACILITY_DOCUMENTS } from "routes/Customer/Facility/Documents";
import { documentTypeToLabel } from "util/constants";
import { OmnisearchAutocomplete } from "./OmnisearchAutocomplete";

// disabledDocumentIds is used to disable documents that are already selected
// and should not be selected again
type FacilityDocumentPickerProps = {
  facilityId: string;
  documentTypes: DocumentType[];
  onSelect: (document: FacilityDocumentPickerItem) => Promise<void>;
  disabled: boolean;
  disabledDocumentIds?: string[];
};

export type FacilityDocumentPickerItem =
  FacilityDocumentsQuery["documents"]["items"][number];

export const FacilityDocumentPicker = ({
  facilityId,
  documentTypes,
  onSelect,
  disabled,
  disabledDocumentIds,
}: FacilityDocumentPickerProps) => {
  return (
    <Stack spacing={2}>
      <Typography variant="body2">
        Select an existing document or upload a new file.
      </Typography>
      <OmnisearchAutocomplete
        runInitialSearch
        disabled={disabled}
        dataQuery={FACILITY_DOCUMENTS}
        defaultSearch={`facilityId:${facilityId} documentType:${documentTypes.join(
          "|"
        )}`}
        dataQueryVariables={{
          facilityId,
          sort: [{ field: "createdAt", sort: "desc" }],
          page: 0,
          pageSize: 10,
        }}
        getItems={(data) => data.documents.items}
        onSelectionChange={async (selected) => {
          if (selected) {
            await onSelect(selected);
          }
        }}
        renderOption={(props, item) => (
          <ListItem
            key={item.id}
            {...props}
            disablePadding
            sx={{
              opacity: disabledDocumentIds?.includes(item.id) ? 0.5 : 1,
              pointerEvents: disabledDocumentIds?.includes(item.id)
                ? "none"
                : "auto",
            }}
          >
            <ListItemText
              primary={item.title}
              secondary={documentTypeToLabel(item.documentType)}
            />
          </ListItem>
        )}
      />
    </Stack>
  );
};
