import { gql } from "generated-graphql";
import { reportStepMetadata } from "util/constants";
import { StepperPageHeader } from "../StepperPageHeader";
import { useHmbpFeature } from "../useHmbp";
import { useReport } from "../useReport";
import { HmbpForm } from "./HmbpForm";
import { StateInformationForm } from "./StateInformationForm";

gql(`
  fragment stateInfo on Facility {
    stateFields {
      ...dynamicField
    }
    undergroundStorageTanks {
      ...undergroundStorageTank
    }
  }
`);

export function StateInformation() {
  const { data } = useReport();
  const hmbp = useHmbpFeature(data?.tierIIReport?.facility.state ?? "");

  return (
    <>
      <StepperPageHeader
        header="State Information"
        description={
          hmbp
            ? reportStepMetadata.STATE_INFORMATION.hmbpDescription
            : reportStepMetadata.STATE_INFORMATION.overviewDescription
        }
      />
      {hmbp ? <HmbpForm /> : <StateInformationForm />}
    </>
  );
}
