import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Check from "@mui/icons-material/Check";
import { Box, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { FacilitiesExportButton } from "components/FacilitiesExportButton";
import { Importer } from "components/Importer";
import { ImportButtonWithDialog } from "components/Importer/ImportDialog";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getChemicalsBreadcrumb,
  getChemicalsInventoryBreadcrumb,
} from "util/breadcrumb";
import { gql } from "../../../../generated-graphql";
import { InventoryFacilitiesQuery } from "../../../../generated-graphql/graphql";
import { useBreadcrumb } from "../../../../hooks/useBreadcrumbs";

type Row = InventoryFacilitiesQuery["chemicalFacilities"]["items"][0];

const INVENTORY_FACILITIES = gql(`
  query InventoryFacilities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    chemicalFacilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        collectionMode
        streetAddress1
        city
        state
        isSubjectToEmergencyPlanning
      }
      count
    }
  }
`);

export function FacilityInventoryTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { tenantId } = useParams<{ tenantId: string }>();
  const { isStaff } = useCurrentUser();

  useBreadcrumb([
    {
      label: "Chemicals",
      to: tenantId ? getChemicalsBreadcrumb(tenantId) : undefined,
    },
    {
      label: "Inventory",
      to: tenantId ? getChemicalsInventoryBreadcrumb(tenantId) : undefined,
    },
  ]);

  const columns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: "facility",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 0.5,
        valueGetter(params) {
          return params.row.name;
        },
      },
      {
        field: "alternateId",
        headerName: "Alternate ID",
        flex: 0.25,
        valueGetter(params) {
          return params.row.customerFacilityId;
        },
      },
      {
        field: "streetAddress1",
        headerName: "Street Address",
        flex: 0.5,
        sortable: false,
      },
      {
        field: "city",
        headerName: "City",
        flex: 0.25,
      },
      {
        field: "state",
        headerName: "State",
        flex: 0.25,
      },
      {
        field: "emergencyPlanning",
        headerName: "Emergency Planning?",
        flex: 0.4,
        type: "boolean",
        headerAlign: "center",
        renderCell: (params) => {
          const { isSubjectToEmergencyPlanning } = params.row;
          return (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: "100%" }}
            >
              {isSubjectToEmergencyPlanning ? <Check /> : "-"}
            </Stack>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.2,
        getActions: (params) => [
          <Tooltip title="View facility" key={1}>
            <GridActionsCellItem
              onClick={() => navigate(params.row.id)}
              label="Show Organization Details"
              icon={<ArrowCircleRightIcon />}
            />
          </Tooltip>,
        ],
      },
    ],
    [navigate]
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const importExportButtons = useMemo(() => {
    const buttons = [
      <FacilitiesExportButton key="export" tenantId={tenantId} />,
    ];
    if (isStaff) {
      buttons.push(
        <ImportButtonWithDialog
          key="import"
          importers={[
            {
              name: "Facilities",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.Facility}
                  refetchQueries={["InventoryFacilities"]}
                />
              ),
            },
            {
              name: "Facility Extensions (state fields, etc)",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.FacilityStateField}
                  refetchQueries={["InventoryFacilities"]}
                />
              ),
            },
            {
              name: "Facility Alternate IDs",
              importer: (
                <Importer
                  templateType={OneSchemaTemplateType.FacilityAlternateId}
                  refetchQueries={["InventoryFacilities"]}
                />
              ),
            },
          ]}
        />
      );
    }
    return buttons;
  }, [isStaff, tenantId]);

  return (
    <Box sx={{ pt: 3 }}>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={INVENTORY_FACILITIES}
        defaultSearch={`tenantId:${tenantId}`}
        getItems={(data) => data.chemicalFacilities.items}
        getCount={(data) => data.chemicalFacilities.count}
        columnVisibilityModel={{
          alternateId: !isSmallScreen,
          streetAddress: !isSmallScreen,
          city: !isSmallScreen,
          reportingChemicalCount: !isSmallScreen,
          isSubjectToEmergencyPlanning: !isSmallScreen,
        }}
        initialSortModel={[{ field: "facility", sort: "asc" }]}
        noDataMessage="No facilities have been added to this organization yet."
        onRowClick={({ row }) => navigate(row.id)}
        commandButtons={importExportButtons}
      />
    </Box>
  );
}
