import { Grid, Typography } from "@mui/material";
import { DynamicField } from "generated-graphql/graphql";
import { groupBy } from "lodash";
import { Control, FieldValues, Path } from "react-hook-form";
import { DynamicField as DynamicFieldComponent } from "./DynamicField";

export const DynamicFieldLayout = <TFieldValues extends FieldValues>(props: {
  fields: (DynamicField & { key: Path<TFieldValues> })[];
  control: Control<TFieldValues>;
  disabled?: boolean;
  grouping?: boolean;
}) => {
  const { grouping, fields, ...fieldProps } = props;
  const groupedFields = grouping
    ? groupBy(fields, (field) => field.group || "default")
    : undefined;

  return (
    <Grid container spacing={3} marginTop={1}>
      {groupedFields ? (
        Object.entries(groupedFields).map(([group, groupFields]) => {
          return (
            <Grid container item spacing={3} key={group}>
              {group !== "default" && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{group}</Typography>
                </Grid>
              )}
              <Fields fields={groupFields} {...fieldProps} />
            </Grid>
          );
        })
      ) : (
        <Fields fields={fields} {...fieldProps} />
      )}
    </Grid>
  );
};

const Fields = <TFieldValues extends FieldValues>({
  fields,
  control,
  disabled,
}: {
  fields: (DynamicField & { key: Path<TFieldValues> })[];
  control: Control<TFieldValues>;
  disabled?: boolean;
}) => {
  return fields.map((field) => (
    <Grid
      item
      xs={field.layout?.xs || 12}
      sm={field.layout?.sm}
      md={field.layout?.md}
      lg={field.layout?.lg}
      xl={field.layout?.xl}
      key={field.key}
    >
      <DynamicFieldComponent
        name={field.key}
        control={control}
        dynamicField={field}
        disabled={disabled}
      />
    </Grid>
  ));
};
