import { Chip, PaletteColor, Stack, useTheme } from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  JobStatus,
  JobTask,
  StaffJobsTableQuery,
  StaffJobsTableQueryVariables,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useSelectedIdInUrl } from "hooks/useSelectedIdInUrl";
import { useCallback, useMemo } from "react";
import { prettyPrintDateTime } from "util/dates";
import { STAFF_JOBS_TABLE_QUERY } from "./api";
import { JobDialog } from "./JobDialog";
import { getJobStatusColor } from "./utils";

type JobRow = StaffJobsTableQuery["jobs"]["items"][number];

export const Jobs = () => {
  const theme = useTheme();
  useBreadcrumb({ label: "Jobs" });

  const {
    selectedId: jobId,
    pushIdToUrl,
    popIdFromUrl,
  } = useSelectedIdInUrl("jobId");

  const getJobStatusColorCallback = useCallback(
    (jobStatus: JobStatus | undefined | null) => {
      return getJobStatusColor({
        jobStatus,
        theme,
      });
    },
    [theme]
  );

  const columns: OmnisearchGridColDef<JobRow>[] = useMemo(
    () => [
      {
        headerName: "Job ID",
        field: "id",
        flex: 1,
      },
      {
        headerName: "Tenant",
        field: "tenantName",
        flex: 1.2,
        sortable: false,
        valueGetter(params) {
          return params.row.tenant?.name ?? "";
        },
      },
      {
        headerName: "Task",
        field: "task",
        filterKeyType: "enum",
        enumValues: Object.values(JobTask),
        flex: 1,
      },
      {
        headerName: "Status",
        field: "status",
        filterKeyType: "enum",
        enumValues: Object.values(JobStatus),
        flex: 0.7,
        renderCell({ value }: { value?: JobStatus }) {
          const color: PaletteColor = getJobStatusColorCallback(value);
          return (
            <Chip
              size="small"
              label={value}
              sx={{
                backgroundColor: color.main,
                color: color.contrastText,
              }}
            />
          );
        },
      },
      {
        headerName: "Created At",
        field: "createdAt",
        flex: 1.2,
        filterKeyType: "time",
        renderCell({ value }) {
          return prettyPrintDateTime(value);
        },
      },
      {
        headerName: "Created By",
        field: "createdBy",
        flex: 1.2,
      },
      {
        headerName: "Updated At",
        field: "updatedAt",
        filterKeyType: "time",
        flex: 1.2,
        renderCell({ value }) {
          return prettyPrintDateTime(value);
        },
      },
    ],
    [getJobStatusColorCallback]
  );

  return (
    <Stack py={3}>
      <OmnisearchDataGrid<
        StaffJobsTableQuery,
        StaffJobsTableQueryVariables,
        JobRow
      >
        persistenceKey="staffJobs"
        rowHeight="auto"
        initialSortModel={[{ field: "createdAt", sort: "desc" }]}
        onRowClick={(params) => {
          pushIdToUrl(params.row.id);
        }}
        columns={columns}
        dataQuery={STAFF_JOBS_TABLE_QUERY}
        getItems={(data) => data.jobs.items}
        getCount={(data) => data.jobs.count}
      />
      {!!jobId && (
        <JobDialog
          jobId={jobId}
          onClose={() => {
            popIdFromUrl();
          }}
        />
      )}
    </Stack>
  );
};
