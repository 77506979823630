import { Typography } from "@mui/material";
import MenuBook from "@mui/icons-material/MenuBook";
import { Issue, Permission } from "generated-graphql/graphql";
import pluralize from "pluralize";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { IssueCount } from "components/IssueCount";
import { useAuthorization } from "hooks/useAuthorization";
import { BaseLink } from "./BaseLink";

interface CatalogLinkProps {
  type: keyof typeof modelToSchemas;
  id: string;
  name: string;
  issues: Issue[];
}

// This maps the model type to the issue schemas that are relevant to the model
const modelToSchemas = {
  Chemical: ["Chemical", "ChemicalComponent", "ChemicalStateField"],
  Product: ["Product", "ProductChemical"],
};

export const CatalogLink: React.FC<CatalogLinkProps> = ({
  type,
  id,
  name,
  issues,
}) => {
  const { hasPermissions, loading: authorizationLoading } = useAuthorization();
  const hasChemicalCatalogPermission = hasPermissions([
    Permission.RouteEpcraProducts,
  ]);
  const { tenantId } = useParams<{ tenantId: string }>();
  if (!name || authorizationLoading || !hasChemicalCatalogPermission) {
    return null;
  }

  let link = "";
  if (type === "Chemical") {
    link = `/o/${tenantId}/chemicals/catalog/chemicals/${id}`;
  } else {
    link = `/o/${tenantId}/chemicals/products/${id}`;
  }
  const issueCount = issues.filter(
    (issue) =>
      modelToSchemas[type].includes(issue.modelName) &&
      (issue.modelId === id || issue.schemaPath?.includes(id))
  ).length;

  const linkContent =
    issueCount === 0 ? (
      <>
        <MenuBook fontSize="small" sx={{ mr: 1 }} />
        View the {type.toLowerCase()} catalog entry for{" "}
        <Link
          to={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit", overflow: "hidden" }}
        >
          <Typography noWrap>{name}</Typography>
        </Link>{" "}
      </>
    ) : (
      <>
        Fix&nbsp;
        <IssueCount issueCount={issueCount} />
        &nbsp;
        {pluralize("issue", issueCount)} in the {type.toLowerCase()} catalog for{" "}
        <Link
          to={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "inherit", overflow: "hidden" }}
        >
          <Typography noWrap>{name}</Typography>
        </Link>{" "}
      </>
    );

  return (
    <BaseLink linkContent={linkContent} link={link} issueCount={issueCount} />
  );
};
