import { useMutation, useQuery } from "@apollo/client";
import { HmbpSection } from "encamp-shared/src/hmbp";
import { gql } from "generated-graphql";
import {
  DynamicField,
  GetReportDetailsQuery,
  Issue,
  Permission,
  ProgramArea,
  TierIiReportOrgStatus,
  TierIiReportStep,
} from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useMeasurements } from "hooks/useMeasurements";
import { useProducts } from "hooks/useProducts";
import { kebabCase } from "lodash";
import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  ReportStepMetadata,
  ReportStepState,
  reportStepMetadata,
} from "util/constants";
import { isHmbpIssue, useHmbpFeature, useHmbpIssues } from "./useHmbp";

export type Report = GetReportDetailsQuery["tierIIReport"];

gql(`
  fragment person on Person {
    id
    first
    last
    email
    phones {
      number
      type
    }
  }
`);

gql(`
  fragment facilityContacts on Facility {
    contacts(programArea: $programArea) {
      id
      reportingRoles
      person {
        id
        tenantId
        first
        last
        email
        phones {
          number
          type
        }
      }
    }
  }
`);

gql(`
  fragment reportFacilitySection on Facility {
        id
        name
        dateClosed
        dateInactive
        colocationReportingFacilityId
        isClosed
        isInactive
        isNotReporting
        isNotReportingThroughEncamp
        ...collectionMode
        ...regulatoryInfo
        ...facilityInfo
        ...stateInfo
        ...facilityContacts
  }
`);

export const GetReportDetailsQueryName = "GetReportDetails";
export const GET_REPORT = gql(`
  query GetReportDetails($reportId: ID!, $programArea: ProgramArea, $version: String) {
    tierIIReport(reportId: $reportId, version: $version) {
      id
      tenantId
      reportingYear
      reportKind
      organizationStatus
      encampStatus
      isVerified
      verifiedAt
      notesToRegulators
      touchedSteps
      touchedCASteps
      department
      reviewCompletedByUserId
      facility {
        ...reportFacilitySection
      }
      assignees {
        id
        assignmentType
        person {
          ...person
        }
      }
      reviewers {
        id
        assignmentType
        isComplete
        person {
          ...person
        }
      }
      documents {
        ...DocumentGridRow
        facilities {
          id
          name
        }
      }
      issues {
        jurisdictions
        key
        message
        modelName
        modelId
        metadata
        facilityId
        tenantId
        level
        schemaPath
      }
      undergroundStorageTankStatuses {
        undergroundStorageTankId
        touchedSteps
      }
    }
  }`);

const UPDATE_FACILITY_REPORT = gql(`
  mutation UpdateFacilityReport($id: ID!, $facility: FacilityInput!, $reportId: ID, $programArea: ProgramArea) {
    updateFacility(id: $id, facility: $facility, reportId: $reportId) {
      ...reportFacilitySection
    }
  }`);

export function useUpdateFacilityReport() {
  return useMutation(UPDATE_FACILITY_REPORT, {
    refetchQueries: [GET_REPORT],
  });
}

export function useReport() {
  const { reportId = "" } = useParams<{ reportId: string }>();
  const [searchParams] = useSearchParams();
  const version = searchParams.get("version");
  return useQuery(GET_REPORT, {
    variables: {
      reportId,
      programArea: ProgramArea.Epcra,
      // Transitional argument to do side-by-side-testing.
      version: version ?? undefined,
    },
    skip: !reportId,
  });
}

export const regulatoryFieldsExceptFacilityAlternateIds = [
  "isSubjectToChemicalAccidentPrevention",
  "isSubjectToEmergencyPlanning",
  "isSubjectToToxicsReleaseInventoryReporting",
];

// For report validation, these fields that are Regulatory Info fields and
// are not validated on the Facility Profile page
export const regulatoryFields = [
  ...regulatoryFieldsExceptFacilityAlternateIds,
  "facilityAlternateIds",
];

// returns the list of all facility state fields from the report
export function useReportDynamicFields(
  filterFn?: (field: DynamicField) => boolean
): DynamicField[] {
  const { data } = useReport();
  return useMemo(
    () => filterFields(data?.tierIIReport.facility.stateFields ?? [], filterFn),
    [data?.tierIIReport.facility.stateFields, filterFn]
  );
}

export function useReportIssues(
  filterFn?: (
    issue: Issue,
    segments: { entityName: string; id?: string }[]
  ) => boolean
): Issue[] {
  const { data } = useReport();

  return useMemo(
    () =>
      filterIssues(data?.tierIIReport.issues ?? [], (issue) => {
        const segments = splitSchemaPath(issue.schemaPath ?? "");
        return filterFn?.(issue, segments) ?? true;
      }),
    [data?.tierIIReport.issues, filterFn]
  );
}

export function useFacilityInfoIssues() {
  return useReportIssues(
    (issue) =>
      issue.modelName === "Facility" &&
      issue.key !== "contacts" &&
      issue.key !== "documents" &&
      issue.key !== "reportFacilityDocuments" &&
      issue.key !== "facilityChemicals" &&
      !regulatoryFields.some((re) => issue.key?.startsWith(re))
  );
}

export function useRegulatoryInfoIssues() {
  return useReportIssues(
    (issue) =>
      (issue.modelName === "Facility" &&
        regulatoryFields.some((re) => issue.key?.startsWith(re))) ||
      isHmbpIssue(issue, { section: HmbpSection.Regulatory })
  );
}

// schemaPath looks like EntityName<id>.EntityName<id>.EntityName<id>..., and the id is optional
function splitSchemaPath(
  schemaPath: string
): { entityName: string; id?: string }[] {
  return schemaPath.split(".").map((segment) => {
    const [entityName, id] = segment.split("<");
    return { entityName, id: id ? id.slice(0, -1) : undefined };
  });
}

export function useProductIssues() {
  return useReportIssues((issue, segments) => {
    return segments.some(
      (segment) =>
        segment.entityName === "FacilityProduct" ||
        segment.entityName === "FacilityProductMeasurement"
    );
  });
}

export function useChemicalIssues() {
  return useReportIssues((issue, segments) => {
    // use the segments or you'll get a faulty match for ReportingFacilityChemical
    return (
      segments.some((segment) => segment.entityName === "FacilityChemical") ||
      segments.some(
        (segment) => segment.entityName === "FacilityChemicalMeasurement"
      )
    );
  });
}

export function useReportingFacilityChemicalIssues() {
  return useReportIssues(
    (issue, segments) =>
      segments.some(
        (segment) => segment.entityName === "ReportingFacilityChemical"
      ) &&
      segments.every(
        (segment) => segment.entityName !== "ReportingFacilityChemicalOrigin"
      )
  );
}

export function useContactIssues() {
  return useFacilityContactIssues().concat(useFacilityContactModelIssues());
}

export function useFacilityContactIssues() {
  return useReportIssues((issue) => issue.key === "contacts");
}

export function useFacilityContactModelIssues() {
  return useReportIssues(
    (issue) =>
      issue.modelName === "FacilityContact" || issue.modelName === "Person"
  );
}

export function useFacilityContactIssuesForModel(modelId: string) {
  return useReportIssues(
    (issue) =>
      issue.modelName === "FacilityContact" && issue.modelId === modelId
  );
}

export function usePersonIssuesForModel(modelId: string) {
  return useReportIssues(
    (issue) => issue.modelName === "Person" && issue.modelId === modelId
  );
}

export function useDocumentIssuesForModel(modelId: string) {
  return useReportFacilityDocumentModelIssues().filter(
    (issue) => issue.modelId === modelId
  );
}

export function useReportFacilityDocumentIssues() {
  return useReportIssues(
    (issue) =>
      issue.key === "reportFacilityDocuments" &&
      issue.modelName === "TierIIReport"
  );
}

export function useReportFacilityDocumentModelIssues() {
  return useReportIssues(
    (issue) => issue.modelName === "ReportFacilityDocument"
  );
}

export function useDocumentIssues() {
  return useReportIssues((issue) => issue.modelName === "Document");
}

export function useAllDocumentIssues() {
  const documentIssues = useDocumentIssues();
  const reportFacilityDocumentIssues = useReportFacilityDocumentIssues();
  const reportFacilityDocumentModelIssues =
    useReportFacilityDocumentModelIssues();
  const allIssues = reportFacilityDocumentIssues
    .concat(reportFacilityDocumentModelIssues)
    .concat(documentIssues);
  const dedupedByDocumentIdAndMessage = allIssues.filter(
    (issue, index, self) =>
      index ===
      self.findIndex(
        (t) => t.modelId === issue.modelId && t.message === issue.message
      )
  );
  return dedupedByDocumentIdAndMessage;
}

export function useStateInfoIssues() {
  const { data } = useReport();
  const hmbp = useHmbpFeature(data?.tierIIReport.facility.state ?? "");
  const hmbpIssues = useHmbpIssues();
  const stateFieldIssues = useReportIssues(
    (issue) => issue.modelName === "FacilityStateField"
  );
  return hmbp ? hmbpIssues : stateFieldIssues;
}

export function useVerifyAndSubmitIssues() {
  return useReportIssues((issue) => issue.modelName === "TierIIReport");
}

export function useIssuesGroupedByStep() {
  return {
    [TierIiReportStep.Facility]: useFacilityInfoIssues(),
    [TierIiReportStep.Chemicals]: useChemicalIssues(), // TODO: will this be Measurement, FacilityChemical, RFC, or all three?
    [TierIiReportStep.Products]: useProductIssues(), // TODO: this will be FacilityProduct issues
    [TierIiReportStep.OtherChemicals]: useChemicalIssues(), // TODO: this will be FacilityChemical issues
    [TierIiReportStep.ChemicalSummary]: useReportingFacilityChemicalIssues(),
    [TierIiReportStep.Contacts]: useContactIssues(),
    [TierIiReportStep.Documents]: useAllDocumentIssues(),
    [TierIiReportStep.Regulatory]: useRegulatoryInfoIssues(),
    [TierIiReportStep.ReportOverview]: [],
    [TierIiReportStep.Review]: [], // No need to show count of all issues here
    [TierIiReportStep.StateInformation]: useStateInfoIssues(),
    [TierIiReportStep.VerifyAndSubmit]: useVerifyAndSubmitIssues(),
  };
}

export function filterIssues(
  issues: Issue[],
  filterFn?: (issue: Issue) => boolean
): Issue[] {
  return issues.filter((issue) => filterFn?.(issue) ?? true) ?? [];
}

export function filterFields(
  fields: DynamicField[],
  filterFn?: (field: DynamicField) => boolean
): DynamicField[] {
  return fields.filter((field) => filterFn?.(field) ?? true) ?? [];
}

export type StepState = {
  status: ReportStepState;
  issues: Issue[];
  metaData: ReportStepMetadata;
};

export type ReportSteps = {
  [key in TierIiReportStep]?: StepState;
};

export function useReportSteps(): ReportSteps {
  const { data } = useReport();
  const report = data?.tierIIReport;
  const issues = useIssuesGroupedByStep();
  const chemicalSteps = useChemicalSteps(data?.tierIIReport.facility.id);
  const { hasPermissionForFacility } = useAuthorization();
  const canVerifyAndSubmit = useMemo(
    () =>
      hasPermissionForFacility(report?.facility.id ?? "", [
        Permission.VerifyTierIiReport,
      ]),
    [report?.facility.id, hasPermissionForFacility]
  );
  // this makes it easier to place the state step in the correct order
  // instead of splicing it in by index
  const stateStep = useMemo(
    () =>
      report?.facility.stateFields.length
        ? [TierIiReportStep.StateInformation]
        : [],
    [report]
  );

  const reportSteps: ReportSteps = useMemo(() => {
    if (
      !report ||
      report.organizationStatus === TierIiReportOrgStatus.NotReporting ||
      report.organizationStatus === TierIiReportOrgStatus.Verified
    ) {
      return {};
    }

    const steps: TierIiReportStep[] = [
      TierIiReportStep.Facility,
      TierIiReportStep.Regulatory,
      ...chemicalSteps,
      TierIiReportStep.Contacts,
      TierIiReportStep.Documents,
      ...stateStep,
      TierIiReportStep.Review,
    ];

    if (canVerifyAndSubmit) {
      steps.push(TierIiReportStep.VerifyAndSubmit);
    }

    const stepsObject: ReportSteps = {};

    steps.forEach((step) => {
      let stepIssues: Issue[] = [];
      if (step !== TierIiReportStep.VerifyAndSubmit) {
        stepIssues = issues[step] ?? [];
      }
      stepsObject[step] = {
        status: getStepStatus(step, report, issues),
        issues: stepIssues,
        metaData: reportStepMetadata[step],
      };
    });
    return stepsObject;
  }, [report, chemicalSteps, stateStep, canVerifyAndSubmit, issues]);

  return reportSteps;
}

export function useStepsByStatus(status: ReportStepState) {
  const stepStatuses = useReportSteps();
  return Object.entries(stepStatuses)
    .filter(([, stat]) => stat.status === status)
    .map(([step]) => step as TierIiReportStep);
}

export function useInvalidSteps() {
  return useStepsByStatus(ReportStepState.Invalid);
}

export function useNotStartedSteps() {
  return useStepsByStatus(ReportStepState.NotStarted);
}

export function useDoneSteps() {
  return useStepsByStatus(ReportStepState.Done);
}

export function useTouchedSteps() {
  const report = useReport();
  return report.data?.tierIIReport.touchedSteps ?? [];
}

export function useStepStatus(step: TierIiReportStep): ReportStepState {
  const { data } = useReport();
  const issues = useIssuesGroupedByStep();
  if (!data) return ReportStepState.NotStarted;

  return getStepStatus(step, data.tierIIReport, issues);
}

function getStepStatus(
  step: TierIiReportStep,
  report: Report,
  issues: { [key in TierIiReportStep]?: Issue[] }
): ReportStepState {
  if (step === TierIiReportStep.Review) {
    return report.reviewCompletedByUserId
      ? ReportStepState.Done
      : ReportStepState.NotStarted;
  }

  if (
    step === TierIiReportStep.StateInformation &&
    report.facility.stateFields.length === 0
  ) {
    return ReportStepState.Done;
  }
  if (step === TierIiReportStep.VerifyAndSubmit) {
    return report.organizationStatus === TierIiReportOrgStatus.Verified
      ? ReportStepState.Done
      : ReportStepState.NotStarted;
  }

  const touchedSteps = report.touchedSteps;

  return issues[step]?.length
    ? ReportStepState.Invalid
    : touchedSteps?.includes(step)
    ? ReportStepState.Done
    : ReportStepState.NotStarted;
}

export function stepToRoute(step: TierIiReportStep) {
  if (step === TierIiReportStep.VerifyAndSubmit) return "verify-submit";
  return kebabCase(step);
}

export const stepToDisplayName: Record<TierIiReportStep, string> = {
  [TierIiReportStep.ReportOverview]: "Overview",
  [TierIiReportStep.Facility]: "Facility",
  [TierIiReportStep.Regulatory]: "Regulatory",
  [TierIiReportStep.Chemicals]: "Chemicals",
  [TierIiReportStep.Products]: "Products",
  [TierIiReportStep.OtherChemicals]: "Other Chemicals",
  [TierIiReportStep.ChemicalSummary]: "Chemical Summary",
  [TierIiReportStep.Contacts]: "Contacts",
  [TierIiReportStep.Documents]: "Documents",
  [TierIiReportStep.StateInformation]: "State Information",
  [TierIiReportStep.Review]: "Review",
  [TierIiReportStep.VerifyAndSubmit]: "Verify & Submit",
};

export function useChemicalSteps(facilityId: string | undefined) {
  const { hasProducts } = useProducts();
  const { hasMeasurements } = useMeasurements(facilityId);

  if (hasProducts) {
    return [
      TierIiReportStep.Products,
      TierIiReportStep.OtherChemicals,
      TierIiReportStep.ChemicalSummary,
    ];
  }

  if (hasMeasurements) {
    return [TierIiReportStep.Chemicals, TierIiReportStep.ChemicalSummary];
  }

  return [TierIiReportStep.Chemicals];
}
